import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import '../css/footer.css';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer>
            <div className="footer-content">
                <div className="logo">
                    {/* <img src={process.env.PUBLIC_URL + '/final_logo.png'} alt="Footer Logo" /> */}
                </div>
                <div className="social-media-links">            
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </div>
                <div>
                    {/* <p><FontAwesomeIcon icon={faEnvelope} /> info@awebllc.com</p> */}
                    <p><FontAwesomeIcon icon={faPhone} /> +1 480 660 0814</p>                           
                </div>
            </div>
        </footer>
    );
}

export default Footer;
