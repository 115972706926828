import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is AstroVoice?",
            answer: "AstroVoice is an advanced SaaS solution for optimizing customer communication through automated voice and text interactions."
        },
        {
            question: "How do I create an account?",
            answer: "You can create an account by clicking the 'Sign Up' button on the landing page and following the instructions."
        },
        {
            question: "Is there a trial period?",
            answer: "Yes, we offer a free trial period to help you explore all features before committing."
        },
        {
            question: "How can I contact support?",
            answer: "You can reach our support team via the 'Contact Us' section in your account settings."
        },
        {
            question: "Is my data secure?",
            answer: "Absolutely. We prioritize data security and comply with all relevant regulations to protect your information."
        },
        {
            question: "What features does AstroVoice offer?",
            answer: "AstroVoice offers a variety of features including automated call center operations, on-demand appointment scheduling, intelligent virtual assistants, conversational interactive voice response, voice analytics, and extensive communication management tools."
        },
        {
            question: "Can I customize my AstroVoice experience?",
            answer: "Yes, AstroVoice allows for extensive customization, including personalized prompts for call handling and the integration of your own branding."
        },
        {
            question: "What types of communication can I manage with AstroVoice?",
            answer: "AstroVoice supports SMS and voice calls, allowing you to manage communication seamlessly through multiple channels."
        },
        {
            question: "How does the AI-powered FAQ support work?",
            answer: "AstroVoice features AI-powered FAQ support which helps answer common inquiries and troubleshoot issues, improving efficiency in customer service."
        },
        {
            question: "Is AstroVoice suitable for any industry?",
            answer: "Yes, AstroVoice is designed to cater to businesses across diverse industries, offering flexible solutions that adapt to different needs."
        },
        {
            question: "What kind of analytics does AstroVoice provide?",
            answer: "AstroVoice provides detailed dashboards with insights into campaign performance, user engagement, and communication effectiveness, helping you to optimize your strategies."
        },
        {
            question: "Can I integrate AstroVoice with other tools?",
            answer: "Absolutely! AstroVoice offers API support for third-party integrations, allowing you to connect with existing tools and enhance productivity."
        },
        {
            question: "How does appointment scheduling work with AstroVoice?",
            answer: "AstroVoice simplifies appointment scheduling by handling the entire process over the phone, including checking availability, confirming appointments, and sending reminders."
        },
        {
            question: "What support options are available?",
            answer: "Our dedicated communication experts provide ongoing support and consultation services to assist you in optimizing your strategies and leveraging the full capabilities of AstroVoice."
        },
        {
            question: "How does call escalation and redirection function?",
            answer: "AstroVoice allows for quick and efficient call escalation and redirection to specific users or departments, enhancing response times and ensuring customer satisfaction."
        },
        {
            question: "What is the process for tracking conversation history?",
            answer: "AstroVoice maintains a conversation history tracking system that allows for easy reference and analysis, ensuring that you have access to customer interactions whenever needed."
        },
        {
            question: "What are the different pricing plans available?",
            answer: "AstroVoice offers various pricing plans including combined plans for voice and text messaging, standalone voice agent plans, and text messaging plans, designed to fit different business requirements."
        },
        {
            question: "Do I have options for managing my data?",
            answer: "Yes, AstroVoice provides options for users to manage their consent and data preferences, maintaining compliance with relevant regulations."
        },
        {
            question: "What is the setup fee associated with AstroVoice?",
            answer: "There is a one-time setup fee of $500 that covers the setup and initial support when you start using AstroVoice."
        }
    ];

    const handleToggle = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div id="faq"className="faq">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-list">
                {faqs.map((item, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question" onClick={() => handleToggle(index)}>
                            <h3>{item.question}</h3>
                            <span className="toggle-icon">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        {activeIndex === index && <p className="faq-answer">{item.answer}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;