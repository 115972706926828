import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faComments, faSync, faBars, faHome, faUsers, faEnvelope, faCog, faPhone, faChalkboardTeacher, faCommentDots, faMicrophone, faHistory, faAddressBook, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from "react-router-dom";

import '../css/sidebar.css';

const Sidebar = () => {
    const {newView, setNewView} = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
    const navigate = useNavigate();

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutsideSidebar = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('hmac_key');
        navigate('/auth');
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideSidebar);

        return () => {
            document.removeEventListener('click', handleClickOutsideSidebar);
        };
    }, []);

    return (
        <div id='sidebar-wrap' ref={sidebarRef}>
            <div className="toggle-menu" onClick={handleToggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
                <ul className={`navigation ${isOpen ? 'fadeIn' : 'fadeOut'}`}>
                <li><FontAwesomeIcon icon={faChalkboardTeacher} /> <a onClick={() => {setNewView('Onboarding');handleToggleMenu();}}>Onboarding</a></li>
<li><FontAwesomeIcon icon={faCommentDots} /> <a onClick={() => {setNewView('Astrotext');handleToggleMenu();}}>Astrotext</a></li>
<li><FontAwesomeIcon icon={faMicrophone} /> <a onClick={() => {setNewView('Astrovoice');handleToggleMenu();}}>AstroVoice</a></li>
<li><FontAwesomeIcon icon={faHistory} /> <a onClick={() => {setNewView('CombinedCommunicationTimeline');handleToggleMenu();}}>Call Logs</a></li>
{/* <li><FontAwesomeIcon icon={faHistory} /> <a onClick={() => {setNewView('Incoming');handleToggleMenu();}}>Call Logs</a></li> */}
<li><FontAwesomeIcon icon={faAddressBook} /> <a onClick={() => {setNewView('Contacts');handleToggleMenu();}}>Contacts</a></li>
<li><FontAwesomeIcon icon={faSignOutAlt} /> <a onClick={() => {handleLogout()}}>Logout</a></li>
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;