import React, { useState } from 'react';
import './LandingHeader.css';

const LandingHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const smoothScrollTo = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="landing-header">
            <div className="landingLogo">
                <img src={process.env.PUBLIC_URL + '/final_logo.png'} alt="AstroVoice Logo" />
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <span className={menuOpen ? "open" : ""}>&#9776;</span>
            </div>
            <nav className={menuOpen ? "open" : ""}>
                <ul>
                    <li><a onClick={(e) => smoothScrollTo(e, 'how-it-works')} href="#how-it-works">How It Works</a></li>
                    <li><a onClick={(e) => smoothScrollTo(e, 'astrovoice-key-features')} href="#astrovoice-key-features">Key Features</a></li>
                    <li><a onClick={(e) => smoothScrollTo(e, 'pricing-section')} href="#pricing-section">Pricing</a></li>
                    <li><a onClick={(e) => smoothScrollTo(e, 'faq')} href="#faq">FAQ</a></li>
                </ul>
            </nav>      
            <div className="user_options">
                <a href="/auth">Sign In</a>
                <a href="/auth" id="free_trial">Start Free Trial</a>
            </div>
        </div>
    );
}

export default LandingHeader;