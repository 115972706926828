import React, { useEffect, useState, useRef } from 'react';
import '../css/messages.css';

const EditMessage = ({ apiServer, campaign, messageData, handleEditChange, handleImageUpload, handleSaveMsg, handleCloseMessages, convertToLocaleTime, deleteMessageMedia }) => {
    const smsCharacterLimit = 160;
    const [characterCount, setCharacterCount] = useState(0);
    const [messagesInfo, setMessagesInfo] = useState({ messageCount: 0, exceedsLimit: false });
    const [selectedFiles, setSelectedFiles] = useState([]); 
    const [imagePreviews, setImagePreviews] = useState([]); 
    const fileInputRef = useRef(null);

    useEffect(() => {
        const calculateMessageInfo = (count) => {
            const messageCount = Math.ceil(count / smsCharacterLimit);
            const exceedsLimit = count > smsCharacterLimit;
            return { messageCount, exceedsLimit };
        };

        const initialCharacterCount = messageData?.message?.length || 0;
        const initialMessagesInfo = calculateMessageInfo(initialCharacterCount);
        setCharacterCount(initialCharacterCount);
        setMessagesInfo(initialMessagesInfo);

        // Reset image previews and selected files
        setSelectedFiles([]); // Clear previous selected files
        setImagePreviews([]); // Clear previous previews

        // Set new media associated with the edited message
        if (Array.isArray(messageData?.media)) {
            const initialImages = messageData.media.map(media => apiServer + media.media_src);
            setImagePreviews(initialImages); // Set previews for the media
        }
    }, [messageData, apiServer]);

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        setCharacterCount(inputValue.length);
        handleEditChange(e);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Get the selected file objects
        
        // Create Blob URLs for image previews
        const newImagePreviews = files.map(file => URL.createObjectURL(file));
        
        // Update the state for previews
        setImagePreviews(prevPreviews => [...prevPreviews, ...newImagePreviews]);
        // Update the state to hold actual file objects for uploads
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleDeleteImage = (index) => {
        // Remove the selected file and its preview by index
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
    
        if (messageData.media && index < messageData.media.length) {
            deleteMessageMedia(messageData.media[index].msg_media_id);
        }
    };

    const handleSave = (e) => {
        handleSaveMsg(e, messageData, false);
    };

    return (
        <div className='messageBox'>
            <button type="button" className="Modal-close" onClick={handleCloseMessages}>X</button>
            <h1>Edit Message</h1>
            <label>Title</label>
            <input 
                type="text" 
                name="message_title" 
                placeholder="Message title" 
                defaultValue={messageData?.message_title}
                onChange={handleEditChange}
            />
            <label>Content</label>
            <textarea 
                name="message" 
                placeholder="Message content" 
                defaultValue={messageData?.message}
                onChange={handleMessageChange}
            ></textarea>
            <p className='info'>We include <span>Txt 2 STOP</span> at the end of all of our messages</p>            
            <div>
                <p>Character Count: {characterCount}</p>
                <p>
                    {messagesInfo.exceedsLimit
                        ? `Exceeds SMS character limit (${smsCharacterLimit} characters)`
                        : `Remaining Characters: ${smsCharacterLimit - characterCount}`}
                </p>
                <p>Number of Messages: {messagesInfo.messageCount}</p>
            </div>
            {imagePreviews.length > 0 && (
                <div className="media-container">
                    {imagePreviews.map((src, index) => (
                        <div key={index} className="media-item">
                            <img src={src} alt={`Preview ${index + 1}`} />
                            <button type="button" className="delete-button" onClick={() => handleDeleteImage(index)}>X</button>
                        </div>
                    ))}
                </div>
            )}
            <label>Attach media</label>
            <div className="file-input-container">
                <input 
                    type="file" 
                    name="messageMedia" 
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    className="hidden-input"
                />
                <button type="button" 
                        className="upload-button"
                        onClick={() => fileInputRef.current.click()}>
                    Upload Files
                </button>
                <p>{selectedFiles.length > 0 ? `${selectedFiles.length} file(s) selected` : 'No files selected'}</p>
            </div>

            <label>Send Date</label>
            <input 
                type="datetime-local" 
                name="message_send_UTC" 
                defaultValue={convertToLocaleTime(messageData?.message_send_UTC)}
                onChange={handleEditChange}
                min={campaign.campaign_start_date + `T00:00`}
                max={campaign.campaign_end_date + `T00:00`}
            />

            <div className='msg_btn_holder'>
                <button type="button" name="save_btn" onClick={handleSave}>Save</button>
                <button type="button" name="cancel_btn" onClick={handleCloseMessages}>Cancel</button>
            </div>
        </div>
    );
}

export default EditMessage;