import React, { useState } from 'react';
import '../css/incoming.css';

const MessageManager = ({ 
    conversations, 
    activeConversation, 
    currentConversation, 
    newMessage, 
    setNewMessage, 
    handleSendMessage, 
    isNewMessageMode, 
    toggleNewMessageMode, 
    handleNewMessage,
    handleSelectConversation,
    recipientNumber,
    setRecipientNumber
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isNewMessageMode) {
                sendNewMessage();
            } else {
                handleSendMessage();
            }
        }
    };

    const sendNewMessage = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            await handleNewMessage();
            setNewMessage(''); // Clear the new message input after sending
        } catch (error) {
            setErrorMessage('Error sending message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div id="sms-container">
                <button className="newBtn" onClick={toggleNewMessageMode}>
                    Start New Conversation
                </button>
                <div className="conversation-container">
                    <div className="conversations-sidebar">
                        {conversations.length > 0 ? (
                            <ul>
                                {conversations.map(conversation => (
                                    <li key={conversation.id}>
                                        <button 
                                            className="newBtn"
                                            onClick={() => handleSelectConversation(conversation)}
                                        >
                                            {conversation.from}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : ("No Text Messages Found...")}
                    </div>

                    {activeConversation ? (
                        <div className="conversation-content">
                            <h2>SMS Conversation With: {activeConversation}</h2>
                            <div className="messages">
                                {currentConversation.map(message => (
                                    <div key={message.id} className="message">
                                        <strong>{message.from}</strong>
                                        <span className="timestamp"> {new Date(message.timestamp).toLocaleTimeString()}</span>
                                        <p>{message.body}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="message-input">
                                <textarea
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    placeholder="Type your reply"
                                    onKeyDown={handleKeyPress}
                                />
                                <button onClick={handleSendMessage} disabled={loading}>Send</button>
                            </div>
                        </div>
                    ) : (isNewMessageMode ? (
                            <div className="new-message-container">
                                <h3>Send a New Message</h3>
                                <input
                                    type="text"
                                    placeholder="Recipient's Phone Number"
                                    value={recipientNumber}
                                    onChange={e => setRecipientNumber(e.target.value)}
                                />
                                <textarea
                                    placeholder="Type your message"
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    onKeyDown={handleKeyPress}
                                />
                                <button onClick={sendNewMessage} disabled={loading}>
                                    {loading ? 'Sending...' : 'Send New Message'}
                                </button>
                                {errorMessage && <p className="error">{errorMessage}</p>}
                            </div>
                        ) : (                        
                            <div>Please select a conversation</div>
                        )
                    )}
                </div>
            </div>                
        </>
    );
};

export default MessageManager;