import React from 'react';
import './KeyFeatures.css';
import { FaUserCheck } from 'react-icons/fa';
import { GoCheckCircleFill } from "react-icons/go";

const featuresList = [
    {
        headline: "AstroVoice automates call routing, escalations, and scheduling to improve efficiency and customer satisfaction.",
        features: [
            { title: "User Account Management", description: "Account creation, secure login, and profile customization." },
            { title: "Assistant Capabilities", description: "Diverse assistance types with natural language processing." },
            { title: "Communication Management", description: "Manage SMS and voice calls, including automated messaging." },
            { title: "Customer Support Features", description: "AI-powered FAQ and streamlined technical support routing." },
            { title: "Campaign Management", description: "Create, manage, and track marketing campaigns effectively." },
        ]
    },
    {
        headline: "Streamline your marketing and appointment processes.",
        features: [

            { title: "Appointment Scheduling", description: "On-demand booking abilities with timely reminders." },
            { title: "Knowledge Management", description: "Create and manage knowledge bases for enhanced assistance." },
            { title: "Escalation Management", description: "Easily manage escalation contacts and templates." },
            { title: "Data Handling and Compliance", description: "Secure user data handling and compliance management." },
            { title: "Voice Analytics", description: "Analyze conversations for insights on customer sentiment." },            
        ]
    },
    {
        headline: "Secure your data and enhance performance.",
        features: [
            { title: "Integration Flexibility", description: "API support for third-party integrations and custom solutions." },
            { title: "Reliable Infrastructure", description: "Scalable technology designed for performance and reliability." },
            { title: "User-Friendly Interface", description: "Intuitive UI/UX designed for an engaging user experience." },
            { title: "Automated Follow-ups", description: "Send messages and notifications automatically to engage customers." },
            { title: "Customizable Call Handling", description: "Personalized call management aligned with brand identity." },
        ]
    },
    {
        headline: "Enhance customer interactions with our intelligent solutions.",
        features: [

            { title: "Expert Support", description: "Ongoing consultation and support from communication experts." },
            { title: "Intelligent Virtual Assistant", description: "AI-driven assistant for personalized customer interactions." },
            { title: "CIVR Technology", description: "Conversational IVR for a more engaging user experience." },
            { title: "Performance Tracking", description: "Monitor effectiveness and adjust strategies based on metrics." },
            { title: "Enhanced Customer Experience", description: "Leverage AI to provide swift and effective customer service." },            
        ]
    }
];

const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * 5) + 1; // Random number between 1 and 5
    return `astro-text-image-${randomIndex}.jpg`;
};

const KeyFeatures = () => {
    return (
        <div id="astrovoice-key-features" className="astrovoice-key-features">
            <h2>Key Features of AstroVoice</h2>
            {featuresList.map((featureSet, index) => (
                <div className={`astrovoice-features ${index % 2 === 1 ? 'reversed' : ''}`} key={index}>
                    <div className="astrovoice-features-list">
                        <div className='astrovoice-feature-container'>
                            <div className="feature-headline">
                                <h1>{featureSet.headline}</h1>
                            </div>
                            {featureSet.features.map((feature, idx) => (
                                <div className='astrovoice-feature' key={idx}>
                                    <GoCheckCircleFill className="icon" />
                                    <div className='feature-description'>
                                        <h3>{feature.title}</h3>
                                        <p>{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                            <a className="getStarted" href='/auth'>Get Started</a>
                        </div>
                    </div>
                    <div className='astrovoice-feature-img'>
                        <img src={getRandomImage()} alt="AstroVoice Feature" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default KeyFeatures;