import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import './QuestionnaireModal.css';

const QuestionnaireModal = ({ isOpen, onClose }) => {
    const { setLoading, apiUrl, hmac } = useContext(AppContext);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        businessType: '',
        customerCount: '',
        incomingCalls: '',
        outgoingCalls: '',
        incomingTexts: '',
        outgoingTexts: '',
        communicationGoals: [],
        expectedOutboundComm: '',
        budget: '',
        customizationNeeds: '',
        additionalComments: ''
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevState => {
                const updatedGoals = checked 
                    ? [...prevState.communicationGoals, value] 
                    : prevState.communicationGoals.filter(goal => goal !== value);
                return { ...prevState, communicationGoals: updatedGoals };
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();        

        const formDataToSend = new FormData();
        // formDataToSend.append('userHmacId', hmac);
        formDataToSend.append('action', 'completeQuestionnaire');

        Object.keys(formData).forEach((key) => {
            const value = formData[key];
            if (value) {
                formDataToSend.append(key, value);
            }
        });

        formDataToSend.append('questionnaireFirstName', formData.firstName);
        formDataToSend.append('questionnaireLastName', formData.lastName);
        formDataToSend.append('questionnaireEmail', formData.email);
        formDataToSend.append('questionnaireBusinessType', formData.businessType);
        formDataToSend.append('questionnaireCustomerCount', formData.customerCount);
        formDataToSend.append('questionnaireIncomingCalls', formData.incomingCalls);
        formDataToSend.append('questionnaireOutgoingCalls', formData.outgoingCalls);
        formDataToSend.append('questionnaireIncomingTexts', formData.incomingTexts);
        formDataToSend.append('questionnaireOutgoingTexts', formData.outgoingTexts);
        formDataToSend.append('questionnaireCommunicationGoals', JSON.stringify(formData.communicationGoals));
        formDataToSend.append('questionnaireExpectedOutboundComm', formData.expectedOutboundComm);
        formDataToSend.append('questionnaireBudget', formData.budget);
        formDataToSend.append('questionnaireCustomizationNeeds', formData.customizationNeeds);
        formDataToSend.append('questionnaireAdditionalComments', formData.additionalComments);
        
        try {
            setLoading(true);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSuccessMessage('Your questionnaire has been submitted successfully!'); // Set success message
            alert('Your questionnaire has been submitted successfully!'); // Set success message
            onClose();
        } catch (error) {
            console.error('Error submitting questionnaire:', error);
            setSuccessMessage('Error submitting questionnaire, please try again.'); // Optional: set error message
        } finally {
            setLoading(false);
        }
    };

    return (
        isOpen && (
            <div className="modal-overlay questionnaire-modal">
                <div className="modal-content">
                    <div className='questionnaire_content'>
                        <div className="landingLogo">
                            <img src={process.env.PUBLIC_URL + '/final_logo.png'} alt="AstroVoice Logo" />
                        </div>
                        <h2>Communications Questionnaire</h2>
                        {successMessage && <div className="success-message">{successMessage}</div>} {/* Display success message */}
                        <form onSubmit={handleSubmit}>
                            <div className="name-fields">
                                <div className="name-field">
                                    <label htmlFor="firstName">First Name:</label>
                                    <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                </div>
                                <div className="name-field">
                                    <label htmlFor="lastName">Last Name:</label>
                                    <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                </div>
                            </div>

                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

                            <label htmlFor="businessType">Business Type:</label>
                            <select id="businessType" name="businessType" value={formData.businessType} onChange={handleChange} required>
                                <option value="">Select...</option>
                                <option value="ecommerce">E-commerce</option>
                                <option value="service">Service-Based</option>
                                <option value="nonprofit">Non-Profit</option>
                                <option value="retail">Retail</option>
                                <option value="other">Other</option>
                            </select>

                            <label htmlFor="customerCount">Customer Base Monthly:</label>
                            <select id="customerCount" name="customerCount" value={formData.customerCount} onChange={handleChange} required>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <h3>Current Communication Practices</h3>
                            <label htmlFor="incomingCalls">Incoming Calls per Month:</label>
                            <select id="incomingCalls" name="incomingCalls" value={formData.incomingCalls} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <label htmlFor="outgoingCalls">Outgoing Calls per Month:</label>
                            <select id="outgoingCalls" name="outgoingCalls" value={formData.outgoingCalls} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <label htmlFor="incomingTexts">Incoming Texts per Month:</label>
                            <select id="incomingTexts" name="incomingTexts" value={formData.incomingTexts} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <label htmlFor="outgoingTexts">Outgoing Texts per Month:</label>
                            <select id="outgoingTexts" name="outgoingTexts" value={formData.outgoingTexts} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <h3>Desired Communication Goals</h3>
                            <label><input type="checkbox" name="communicationGoals" value="improve_customer_engagement" onChange={handleChange} /> Improve Customer Engagement</label>
                            <label><input type="checkbox" name="communicationGoals" value="automate_customer_interactions" onChange={handleChange} /> Automate Customer Interactions</label>
                            <label><input type="checkbox" name="communicationGoals" value="enhance_appointment_scheduling" onChange={handleChange} /> Enhance Appointment Scheduling</label>
                            <label><input type="checkbox" name="communicationGoals" value="increase_sales" onChange={handleChange} /> Increase Sales through Communication</label>
                            <label><input type="checkbox" name="communicationGoals" value="other" onChange={handleChange} /> Other (please specify)</label>

                            <label htmlFor="expectedOutboundComm">Expected Outbound Calls/Text Messages:</label>
                            <select id="expectedOutboundComm" name="expectedOutboundComm" value={formData.expectedOutboundComm} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="less_than_100">Less than 100</option>
                                <option value="100_500">100 - 500</option>
                                <option value="500_1000">500 - 1,000</option>
                                <option value="more_than_1000">1,000+</option>
                            </select>

                            <h3>Budget Considerations</h3>
                            <label htmlFor="budget">What is your budget range for phone and text communication services?</label>
                            <select id="budget" name="budget" value={formData.budget} onChange={handleChange}>
                                <option value="">Select...</option>
                                <option value="below_100">Below $100/month</option>
                                <option value="below_200">Below $200/month</option>
                                <option value="below_300">Below $300/month</option>
                                <option value="below_400">Below $400/month</option>
                                <option value="below_500">Below $500/month</option>
                                <option value="500_1000">$500 - $1,000/month</option>
                                <option value="1000_2500">$1,000 - $2,500/month</option>
                                <option value="over_2500">Over $2,500/month</option>
                            </select>

                            <label htmlFor="customizationNeeds">Are there additional features or customizations you foresee needing?</label>
                            <textarea id="customizationNeeds" name="customizationNeeds" value={formData.customizationNeeds} onChange={handleChange} placeholder="Please specify..."></textarea>

                            <label htmlFor="additionalComments">Additional Comments:</label>
                            <textarea id="additionalComments" name="additionalComments" value={formData.additionalComments} onChange={handleChange} placeholder="Any other information we should know..."></textarea>

                            <button type="submit">Submit</button>
                        </form>
                    </div>                        
                </div>
            </div>
        )
    );
};

export default QuestionnaireModal;