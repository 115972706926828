import React from 'react';
import { useNavigate} from "react-router-dom";

import './CallToAction.css';

const CallToAction = () => {
    const navControl = useNavigate();
       
    return (
        <div className="elevate-cta">
            <h2 className="cta-title">Ready to Elevate Your Customer Engagement?</h2>
            <p className="cta-description">Join AstroVoice today and transform your communication strategies!</p>
            <div className="cta-buttons-container">
                <button className="cta-button-primary" onClick={() => {navControl('/auth');}} >Sign Up Now</button>
                <button className="cta-button-secondary" onClick={() => {navControl('/auth');}}>Learn More</button>
            </div>
        </div>
    );
}

export default CallToAction;