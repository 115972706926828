import React, { useEffect, useState, useRef } from 'react';
import '../css/messages.css';

const CreateMessage = ({ handleChange, handleImageUpload, handleSaveMsg, newMessage, handleCloseMessages, selectedFiles, setSelectedFiles, imagePreviews, setImagePreviews }) => {
    const smsCharacterLimit = 160;
    const [characterCount, setCharacterCount] = useState(0);
    const [messagesInfo, setMessagesInfo] = useState({ messageCount: 0, exceedsLimit: false });
    
    const fileInputRef = useRef(null);

    useEffect(() => {
        setMessagesInfo(calculateMessageInfo(characterCount));
    }, [characterCount]);

    const calculateMessageInfo = (count) => {
        const messageCount = Math.ceil(count / smsCharacterLimit);
        const exceedsLimit = count > smsCharacterLimit;
        return { messageCount, exceedsLimit };
    };

    const handleSave = (e) => {
        handleSaveMsg(e, newMessage, true);
    };

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        setCharacterCount(inputValue.length);
        handleChange(e);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Get the file objects

        // Create Blob URLs for image previews
        const newImagePreviews = files.map(file => URL.createObjectURL(file)); 

        // Update the state for actual files and previews
        setImagePreviews(prevPreviews => [...prevPreviews, ...newImagePreviews]);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleDeleteFile = (index) => {
        // Remove the selected file and its preview by index
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
        // Make sure to clean up the Blob URL as well
        URL.revokeObjectURL(imagePreviews[index]);
    };

    return (
        <div className="messageContainer">
            <button type="button" className="Modal-close" onClick={handleCloseMessages}>X</button>
            <h1>Create Message</h1>
            <label>Title</label>
            <input
                type="text"
                name="message_title"
                placeholder="Message title"
                defaultValue={newMessage.title}
                onChange={handleChange}
            />
            <label>Content</label>
            <textarea
                name="message"
                placeholder="Message content"
                defaultValue={newMessage.message}
                onChange={handleMessageChange}
            ></textarea>
            <p className='info'>We include <span>Txt 2 STOP</span> at the end of all of our messages</p>            
            <div>
                <p>Character Count: {characterCount}</p>
                <p>
                    {messagesInfo.exceedsLimit
                        ? `Exceeds SMS character limit (${smsCharacterLimit} characters)`
                        : `Remaining Characters: ${smsCharacterLimit - characterCount}`}
                </p>
                <p>Number of Messages: {messagesInfo.messageCount}</p>
            </div>

            <label>Attach media</label>
            <div className="file-input-container">
                <input
                    type="file"
                    name="media"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    className="hidden-input"
                />
                <button type="button" 
                        className="upload-button"
                        onClick={() => fileInputRef.current.click()}>
                    Upload Files
                </button>
                <p>{selectedFiles.length > 0 ? `${selectedFiles.length} file(s) selected` : 'No files selected'}</p>
            </div>

            {imagePreviews.length > 0 && (
                <div className="media-container">
                    {imagePreviews.map((src, index) => (
                        <div key={index} className="media-item">
                            <img src={src} alt={`Preview ${index + 1}`} />
                            <button type="button" className="delete-button" onClick={() => handleDeleteFile(index)}>X</button>
                        </div>
                    ))}
                </div>
            )}

            <label>Send Date</label>
            <input
                type="datetime-local"
                name="message_send_UTC"
                defaultValue={newMessage.sendDate}
                onChange={handleChange}
            />

            <div className="msg_btn_holder">
                <input type="button" name="save_btn" value="Save" onClick={handleSave} />
                <input type="button" name="cancel_btn" value="Cancel" onClick={handleCloseMessages} />
            </div>
        </div>
    );
}

export default CreateMessage;