import React, { useContext, useEffect, useState, useMemo } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import ImportContacts from './ImportContacts';


const useContactsPagination = (groupId, currentPage, contactsPerPage, searchTerm, setListedGroupName) => {
    const { apiUrl, hmac } = useContext(AppContext);
    const [contacts, setContacts] = useState([]);
    const [totalContacts, setTotalContacts] = useState(0);

    useEffect(() => {
        fetchContacts();
    }, [groupId, currentPage, contactsPerPage, searchTerm]);

    const fetchContacts = async () => {
        const startIndex = currentPage * contactsPerPage - contactsPerPage;

        let fd = new FormData();
        fd.append('action', 'fetchContacts');
        fd.append('userHmacId', hmac);
        fd.append('group_id', groupId);
        fd.append('startIndex', startIndex);
        fd.append('perPage', contactsPerPage);
        fd.append('searchTerm', searchTerm);

        const response = await axios.post(apiUrl, fd);
        const data_contacts = response.data.data;

        setContacts(data_contacts);
        setTotalContacts(data_contacts[0]?.total_contacts);
        setListedGroupName(data_contacts[0]?.contact_group_name);
    };

    return { contacts, totalContacts, fetchContacts };
};

const ListGroupContacts = ({ groupId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [importing, setImporting] = useState(false);
    const [groupName, setListedGroupName] = useState('');
    const contactsPerPage = 300;

    const { contacts, totalContacts, fetchContacts } = useContactsPagination(groupId, currentPage, contactsPerPage, searchTerm, setListedGroupName);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const closeImporter = () => { setImporting(false); fetchContacts() };

    const totalPages = useMemo(() => Math.ceil(totalContacts / contactsPerPage), [totalContacts, contactsPerPage]);

    // Generate pagination range
    const getPaginationRange = () => {
        const range = [];
        const maxPageButtons = 5; // Number of buttons to display at once
        const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
        const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
        
        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }

        return range;
    };

    const pageNumbers = getPaginationRange();

    return (
        <>
            <button type="button" className="importContactsBtn newBtn" onClick={() => setImporting(true)}>Import New Contacts</button>
            <div id="contactContainer">
                <input type="text" placeholder="Search contacts" value={searchTerm} onChange={handleSearch} className="contactSearch" />
                {importing && <ImportContacts groupId={groupId} onClose={closeImporter} setCurrentPage={setCurrentPage} />}
                {(totalContacts > 0) ?
                    <h2>You have {totalContacts} contacts in {groupName} Group</h2>
                    :
                    <h2>No Contacts yet...</h2>
                }       
                <ul className="zContract">
                    {contacts && (contacts.map((contact) => (
                        <li key={contact.contact_id}>({contact.contact_number?.substring(1, 4)}) {contact.contact_number?.substring(4, 7)}-{contact.contact_number?.substring(7, 9)}...</li>
                    )))}
                </ul>
                <div className="pagination">
                    <button onClick={() => handlePagination(currentPage > 1 ? currentPage - 1 : 1)} disabled={currentPage === 1}>
                        Previous
                    </button>
                    {pageNumbers.map((pageNumber) => (
                        <button 
                            key={pageNumber} 
                            onClick={() => handlePagination(pageNumber)} 
                            className={pageNumber === currentPage ? 'active' : ''}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button onClick={() => handlePagination(currentPage < totalPages ? currentPage + 1 : totalPages)} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default ListGroupContacts;