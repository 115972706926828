import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import Modal from 'react-modal';
import axios from 'axios';
import '../css/messages.css';
import Countdown from './Countdown';
import { Tooltip } from 'react-tooltip';
import EditMessage from './EditMessage';
import CreateMessage from './CreateMessage';


  
const Messages = ({ campaign, setShowMessages }) => {
    const { apiServer, apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [messages, setMessages] = useState([]);
    const [isEditing, setIsEditing] = useState([]);
    const [IsWorkingWithMessage, setIsWorkingWithMessage] = useState(false);
    const [messageData, setMessageData] = useState(0);
    const [newMessage, setNewMessage] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]); // Store Blob URLs for previews
    const [campaignStartDate, setCampaignStartDate] = useState();
    // const [campaignEndDate, setCampaignEndDate] = useState();
    const [messagesModalVisible, setMessagesModalVisible] = useState(false);
       
    useEffect(() => {
        fetchMessagesFromAPI()
            .then(apiMessages => {setMessages(apiMessages)})
            .catch(error => console.log(error));
    }, []);

    const handleGenerateAI = async () => {
        setLoading(true);
        await fetchAIMessages();
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));
    };

    const fetchAIMessages = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'generateAIMessages');
            formData.append('campaignId', campaign.campaign_id);
            const response = await axios.post(apiUrl, formData);
            setLoading(false);
        } catch (error) {
        } finally {
            setLoading(false);
        } 
    }    


    const fetchMessagesFromAPI = async () => {
        let formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'listMessages');
        formData.append('campaignId', campaign.campaign_id);
        const response = await axios.post(apiUrl, formData);
        return response.data.data;
    }

    function convertToLocaleTime(utcDateTime) {
        const utcDate = new Date(utcDateTime);
        const year = utcDate.getFullYear();
        const month = String(utcDate.getMonth() + 1).padStart(2, '0');
        const day = String(utcDate.getDate()).padStart(2, '0');
        const hours = String(utcDate.getHours()).padStart(2, '0');
        const minutes = String(utcDate.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const authorizeMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'authorizeMessage');
        formData.append('messageId', messageId);
        formData.append('campaignId', campaign?.campaign_id);
      
        const response = await axios.post(apiUrl, formData);
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));
        
      
        // Check the response status and log if successful
        if (response.status === 200) {
        //   console.log(`Message with ID ${messageId} authorized.`);
        }
    }
      
    const deauthorizeMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'deauthorizeMessage');
        formData.append('messageId', messageId);
        formData.append('campaignId', campaign?.campaign_id);
      
        const response = await axios.post(apiUrl, formData);
        fetchMessagesFromAPI()
            .then(apiMessages => setMessages(apiMessages))
            .catch(error => console.log(error));        
      
        // Check the response status and log if successful
        if (response.status === 200) {
        }
    }

    const prefaceEditMessage = (message) => {
        setIsWorkingWithMessage(true);
        setMessageData(message);
        setIsEditing(true);        
    }

    const deleteMessage = async (messageId) => {
        const formData = new FormData();
        formData.append('userHmacId', hmac);
        formData.append('action', 'deleteMessage');
        formData.append('messageId', messageId);    
        formData.append('campaignId', campaign?.campaign_id);

        const response = await axios.post(apiUrl, formData);

        // Check the response status and update the messages state if successful
        if (response.status === 200) {
            const updatedMessages = await fetchMessagesFromAPI();
            setMessages(updatedMessages);
        }
    }

    const deleteMessageMedia = async (messageMediaId) => {
        const formData = new FormData();
        formData.append('action', 'deleteMessageMedia');
        formData.append('userHmacId', hmac);
        formData.append('campaignId', campaign?.campaign_id);
        formData.append('messageMediaId', messageMediaId);    

        const response = await axios.post(apiUrl, formData);

        // Check the response status and update the messages state if successful
        if (response.status === 200) {
            const updatedMessages = await fetchMessagesFromAPI();
            setMessages(updatedMessages);
            setMessageData(updatedMessages);
        }
    }    


    const handleCreateNew = () => {
        setIsWorkingWithMessage(true);
        setIsEditing(false);
    }

    const handleChange = (e) => {
        setNewMessage({
            ...newMessage,
            [e.target.name]: e.target.value,
        });
    }

    const handleEditChange = (e) => {
        setMessageData({
            ...messageData,
            [e.target.name]: e.target.value
        });
    }

    const handleCloseMessages = (e) => {
        setMessagesModalVisible(false);
        setIsWorkingWithMessage(false);
        setIsEditing(false);
        
        // Reset the input fields
        setNewMessage({ 
            title: '', // Reset title
            message: '', // Reset message content
            sendDate: '', // Reset send date
        });
        
        // Clear selected files and image previews if necessary
        setSelectedFiles([]); 
        setImagePreviews([]); 
    };

    const handleImageUpload = (e, isCreateMessage) => {
        if (isCreateMessage) {
            setNewMessage({
                ...newMessage,
                media: e.target.files[0]
            });
        }
        else {
            setMessageData({
                ...messageData,
                media: e.target.files[0]
            });
        }
    }

    const handleSaveMsg = async (e, messageData, isCreate) => {      
        e.preventDefault();
        setLoading(true);
        let msgAction = 'createMessage';
    
        try {
            if (!isCreate) {
                msgAction = 'updateMessage';
            }
  
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', msgAction);
            if (!isCreate) {
                formData.append('messageId', messageData?.msg_id);    
            }
            formData.append('campaignId', campaign?.campaign_id);
            formData.append('messageTitle', messageData?.message_title);
            formData.append('message', messageData?.message);
            
            // Append each selected file (not the Blob URL)
            selectedFiles.forEach(file => {
                formData.append('messageMedia[]', file); // Append the actual file object
            });
    
            formData.append('messageSendDate', messageData?.message_send_UTC);
    
            // Send the form data using axios post request
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            // Check the response status and update the messages state if successful
            if (response.status === 200) {
                const updatedMessages = await fetchMessagesFromAPI();
                setMessages(updatedMessages);
                handleCloseMessages();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error saving message:', error);
        }
    };
    

    const handleCloseMessagesDisplay = () => {
        setShowMessages(false);
    }

    return (
        <div id="messageContainer">
            <button type="button" className="Modal-close" onClick={handleCloseMessagesDisplay}>X</button>
            <div className="messageButtons newBtn">
                <button type="button" onClick={handleCreateNew}>Create a new message</button>
                <button type="button" onClick={handleGenerateAI} disabled={loading}>Generate with AI</button>
            </div>            
            <div id="messageHeader">
                <h2>{campaign.campaign_name}</h2>
                <p data-tip={campaign.campaign_description}>
                    {campaign.campaign_description.length > 250
                    ? campaign.campaign_description.substring(0, 250) + "..."
                    : campaign.campaign_description}
                </p>
            </div>

            <Tooltip effect="solid" place="top" />
            
            <>
                <Modal isOpen={IsWorkingWithMessage} onRequestClose={handleCloseMessages}>
                    {IsWorkingWithMessage && !isEditing && (<CreateMessage {...{ apiServer, campaign, handleChange, handleCreateNew, handleImageUpload, handleSaveMsg, messages, newMessage, authorizeMessage, deleteMessage, handleCloseMessages, deleteMessageMedia, selectedFiles, setSelectedFiles, imagePreviews, setImagePreviews}} />)}
                    {IsWorkingWithMessage && isEditing && (<EditMessage {...{ apiServer, campaign, messageData, handleEditChange, handleCreateNew, handleImageUpload, handleSaveMsg, convertToLocaleTime, handleCloseMessages, deleteMessageMedia, selectedFiles, setSelectedFiles, imagePreviews, setImagePreviews}} />)}
                </Modal>
            </>

            <div className='messageHolder'>
                {/* {loading ? "Generating AI Messages..." : "Generate AI Messages"} */}
                <div id="liveMsgContainer" className='campaignMessageContainer'>
                    <h2>Live, Approved Messages</h2>
                    <div className="campaignMessages" >
                        {Array.isArray(messages) && (
                            messages
                                .filter(message => message.message_status === "1")
                                .map(message => (
                                    <div className="live_message message" key={message.msg_id}>
                                        <h3>{message.message_title}</h3>
                                        <p>{message.message}</p>
                                        <h3><Countdown key={message.msg_id} message={message} /></h3>
                                        <div className="messageButtons">
                                            <button onClick={() => deauthorizeMessage(message.msg_id)}>Disapprove</button>
                                            <button onClick={() => prefaceEditMessage(message)}>Edit</button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this message?");
                                                if (confirmDelete) {
                                                    deleteMessage(message.msg_id);
                                                }
                                            }}>Delete</button>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </div>

                <div id="aiMsgContainer" className='campaignMessageContainer'>
                    <h2>Generated Messages</h2>
                    <div className="campaignMessages">
                        {Array.isArray(messages) &&
                            messages
                                .filter(message => message.message_status === "0")
                                .map(message => (
                                    <div className="ai_message message" key={message.msg_id}>
                                        <h3>{message.message_title}</h3>
                                        <p>{message.message}</p>
                                        <h3><Countdown key={message.msg_id} message={message} /></h3>
                                        {/* <p>{message.message_send_UTC}</p> */}
                                        <div className="messageButtons">
                                            <button onClick={() => authorizeMessage(message.msg_id)}>Approve</button>
                                            <button onClick={() => prefaceEditMessage(message)}>Edit</button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this message?");
                                                if (confirmDelete) {
                                                    deleteMessage(message.msg_id);
                                                }
                                            }}>Delete</button>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Message Statuses
// INACTIVE
// ACTIVE
// GENERATED
// COMPLETED
// FAILED

export default Messages;