import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../css/auth.css';

const LoginForm = () => {
    const { apiUrl, loading, setLoading, hmac, setHmac } = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const navigate = useNavigate();

    const setupSession = (hmac) => {
        setHmac(hmac);
        sessionStorage.setItem('hmac_key', hmac);
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'login');
            formData.append('email', email);
            formData.append('password', password);
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setupSession(response.data.data);
                navigate('/');
            } else {
                const errorMessage = response.data.message;
                setLoginError(errorMessage); // Set error message to state
            }
        } catch (error) {
            console.log(error);
            setLoginError("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-form">
            <h4>Login to Your Account</h4>
            {loginError && <div className="error-message">{loginError}</div>}
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
            <div className="login-footer">
                <a href="#" className="forgot-password-link">Forgot Password?</a>
                {/* <p>Don't have an account? <a href="/create-account" className="create-account-link">Create one now!</a></p> */}
            </div>
        </div>
    );
}

export default LoginForm;