import React, { useState, useContext } from 'react';
import { CreateGroup, EditGroup, ListGroups } from './ContactGroups';
import '../css/contacts.css';
import ListGroupContacts from './ListGroupContacts';

const Contacts = () => {
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [showContacts, setShowContacts] = useState(false);
    
    const openCreateModal = () => {
        setCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalOpen(false);
    };

    const openEditModal = (group) => {
        setSelectedGroup(group);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedGroup(null);
        setEditModalOpen(false);
    };

    const handleManageContacts = (group) => {
        setSelectedGroup(group);
        setShowContacts(true);
    };

    const handleBackToGroups = () => {
        setShowContacts(false);
        setSelectedGroup(null);
    };

    return (
        <div id="contact_container" className="content_container">
            <button onClick={openCreateModal} className='newBtn'>Create New Group</button>
            <CreateGroup isOpen={isCreateModalOpen} closeModal={closeCreateModal} />

            {isEditModalOpen && (
                <EditGroup group={selectedGroup} isOpen={isEditModalOpen} closeModal={closeEditModal} />
            )}

            {!showContacts ? (
                <ListGroups 
                    openEditModal={openEditModal} 
                    handleManageContacts={handleManageContacts} 
                />
            ) : (
                <div>
                    <button onClick={handleBackToGroups} className='backBtn'>Back to Groups</button>
                    <ListGroupContacts groupId={selectedGroup.contact_group_id} />
                </div>
            )}
        </div>
    );
};

export default Contacts;