import React from 'react';
import '../css/incoming.css';

const PhoneNumberSelector = ({ userPhoneNumbers, selectedPhoneNumber, onSelectPhoneNumber }) => {
    return (
        <div className="phone-number-bubbles">
            {userPhoneNumbers.map(phone => (
                <div 
                    key={phone.user_phone_id} 
                    className={`phone-number-bubble ${selectedPhoneNumber === phone.phone_number ? 'active' : ''}`} 
                    onClick={() => onSelectPhoneNumber(phone.user_phone_id)}
                >
                    {phone.phone_number}
                </div>
            ))}
        </div>
    );
};

export default PhoneNumberSelector;