import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import '../css/incoming.css';

const CombinedCommunicationTimeline = () => {
    const { apiUrl, hmac, setLoading } = useContext(AppContext);
    const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);
    const [selectedPhoneId, setSelectedPhoneId] = useState(null);
    const [communicationTimeline, setCommunicationTimeline] = useState([]);

    // New state for messaging functionality
    const [newMessage, setNewMessage] = useState('');
    const [recipientNumber, setRecipientNumber] = useState('');
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchUserPhoneNumbers();
    }, []);

    useEffect(() => {
        if (selectedPhoneId) {
            fetchCombinedCommunicationData(selectedPhoneId);
        }
    }, [selectedPhoneId]);

    const fetchUserPhoneNumbers = async () => {
        try {
            const fd = new FormData();
            fd.append('userHmacId', hmac);
            fd.append('action', 'listMyPhoneNumbers');

            const response = await axios.post(apiUrl, fd);

            if (response.data.status === 'success') {
                setUserPhoneNumbers(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchCombinedCommunicationData = async (phoneId) => {
        setLoading(true);
        try {
            const fd = new FormData();
            fd.append('userHmacId', hmac);
            fd.append('action', 'getCombinedTimeline');
            fd.append('phoneNumberId', phoneId);

            const response = await axios.post(apiUrl, fd);

            if (response.data.status === 'success') {
                setCommunicationTimeline(response.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectPhoneNumber = (phoneId) => {
        setSelectedPhoneId(phoneId);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendNewMessage();
        }
    };

    const sendNewMessage = async () => {
        setLoadingMessage(true);
        setErrorMessage('');
        try {
            const fd = new FormData();
            fd.append('userHmacId', hmac);
            fd.append('action', 'sendSMS');
            fd.append('phoneNumberId', selectedPhoneId);
            fd.append('conversationPhoneNumber', recipientNumber);
            fd.append('smsMessage', newMessage);
           
            const response = await axios.post(apiUrl, fd);
            
            if (response.data.status === 'success') {
                setNewMessage(''); // Clear input
                setRecipientNumber(''); // Clear recipient number
                // Optionally, update communication timeline here or fetch again
            } else {
                setErrorMessage('Error sending message. Please try again.');
            }
        } catch (error) {
            setErrorMessage('Error sending message. Please try again.');
        } finally {
            setLoadingMessage(false);
        }
    };

    return (
        <div className="content_container">
            <div>
                <div className="phone-number-selector">
                    {userPhoneNumbers.length === 0 ? (
                        null
                    ) : (
                        userPhoneNumbers.map(phone => (
                            <div
                                key={phone.user_phone_id}
                                className={`phone-number-bubble ${selectedPhoneId === phone.user_phone_id ? 'active' : ''}`}
                                onClick={() => handleSelectPhoneNumber(phone.user_phone_id)}
                                
                            >
                                {phone.phone_number}
                            </div>
                        ))
                    )}
                </div>

                <div className="timeline">
                    {communicationTimeline?.map((item) => (
                        <div key={`${item.id}`} className={`timeline-item ${item.direction}`}>
                            <div className="timeline-point"></div>
                            <div className="timeline-content">
                                <p>
                                    <strong>
                                        {item.source === 'call' ? (
                                            <span>
                                                {item.direction === 'outbound'
                                                    ? `Caller: ${item.from}`
                                                    : `Astrovoice Response: ${item.from}`} 
                                            </span>
                                        ) : (
                                            <span>
                                                {item.direction === 'outbound' 
                                                    ? `Astrotext Response: ${item.to}` 
                                                    : `Incoming Text From: ${item.from}`}
                                            </span>
                                        )}
                                    </strong>
                                </p>
                                <p>{item.body || 'No message content'}</p>
                                <p className="timestamp">{new Date(item.timestamp).toLocaleString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
                
                {/* New Message Section */}
                {userPhoneNumbers?.length > 0 ? 
                    (
                        <>
                            <h3>Send a new Message</h3>
                            <div className="message-input">
                                <input
                                    type="text"
                                    placeholder="Recipient's Phone Number"
                                    value={recipientNumber}
                                    onChange={e => setRecipientNumber(e.target.value)}
                                />
                                <textarea
                                    placeholder="Type your message"
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    onKeyDown={handleKeyPress}
                                />
                                <button onClick={sendNewMessage} disabled={loadingMessage}>
                                    {loadingMessage ? 'Sending...' : 'Send New Message'}
                                </button>
                                {errorMessage && <p className="error">{errorMessage}</p>}
                            </div>
                        </>
                    ):(<p>You don't have a call history yet...</p>)
                }
            </div>
        </div>
    );
};

export default CombinedCommunicationTimeline;