import React from 'react';
import './HowItWorks.css';
import { FaUserCheck, FaBullhorn, FaComments, FaChartLine, FaSupport } from 'react-icons/fa';

const HowItWorks = () => {
    return (
        <div id="how-it-works"className="how-it-works">
            <h1>How Astrovoice Works</h1>
            <div className="features">
                <div className="feature">
                    <FaUserCheck className="icon" />
                    <h3>Easy Account Creation</h3>
                    <p>Start with an easy and secure account creation process, allowing you to customize your profile settings and preferences.</p>
                </div>
                <div className="feature">
                    <FaBullhorn className="icon" />
                    <h3>Campaign Management</h3>
                    <p>Create and manage your communication and marketing campaigns with features like scheduled messaging and calls.</p>
                </div>
                <div className="feature">
                    <FaComments className="icon" />
                    <h3>Engage with Customers</h3>
                    <p>Utilize our natural language processing and voice command integration to provide context-aware assistance and automated communication solutions.</p>
                </div>
                <div className="feature">
                    <FaChartLine className="icon" />
                    <h3>Data Analytics</h3>
                    <p>Leverage insightful data analytics to understand customer sentiments and optimize your strategies for better engagement.</p>
                </div>
                <div className="feature big-feature">
                    <FaUserCheck className="icon" />
                    <h3>Comprehensive Support</h3>
                    <p>Access AI-powered FAQs and technical support routing for efficient resolution of customer queries.</p>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;