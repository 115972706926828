import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import PhoneCallTranscriptions from './phoneCallTranscriptions';
import PhoneNumberSelector from './PhoneNumberSelector';
import MessageManager from './MessageManager';
import CommunicationTypeSelector from './CommunicationTypeSelector';
import '../css/incoming.css';

const Incoming = () => {
    const { apiUrl, hmac, loading, setLoading, selectedContactPhone } = useContext(AppContext);

    const [outgoingPhoneNumber, setOutgoingPhoneNumber] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [selectedPhoneId, setSelectedPhoneId] = useState(selectedContactPhone?.user_phone_id);
    const [showCommunicationTypes, setShowCommunicationTypes] = useState(true);
    const [selectedCommunicationType, setSelectedCommunicationType] = useState('calls');
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isNewMessageMode, setIsNewMessageMode] = useState(false); 
    const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);
    const [recipientNumber, setRecipientNumber] = useState(''); // New state for recipient number    

    useEffect(() => {
        fetchUserPhoneNumbers();
    }, []);

    useEffect(() => {
        if (selectedPhoneNumber) {
            fetchConversationsFromAPI(selectedPhoneNumber);
        }
    }, [selectedPhoneNumber]);

    const fetchUserPhoneNumbers = async () => {
        try {
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listMyPhoneNumbers');
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setUserPhoneNumbers(response.data.data);
            } else {
                setUserPhoneNumbers([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchConversationsFromAPI = async (phoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getIncoming');
            formData.append('phoneNumberId', phoneId);
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                const data = response.data.data;
                const apiConversations = Object.values(data);
                const sortedConversations = apiConversations.sort((a, b) => {
                    const lastMessageA = a.messages?.length ? a.messages[a.messages.length - 1].timestamp : 0;
                    const lastMessageB = b.messages?.length ? b.messages[b.messages.length - 1].timestamp : 0;
                    return new Date(lastMessageB) - new Date(lastMessageA);
                });
                setConversations(sortedConversations);
            } else {
                setConversations([]);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSelectPhoneNumber = (phoneId) => {
        setSelectedPhoneId(phoneId);
        setIsNewMessageMode(false);
        fetchConversationsFromAPI(phoneId);
        setActiveConversation(null);
        setCurrentConversation([]);
    };

    const toggleNewMessageMode = () => {
        setActiveConversation(null);
        setIsNewMessageMode(true);
        if (isNewMessageMode) {
            setOutgoingPhoneNumber('');
            setNewMessage('');
        }
    };

    const handleSelectCommunicationType = (type) => {
        setSelectedCommunicationType(type);
        if (type === 'sms') {
            fetchConversationsFromAPI(selectedPhoneId);
        } else {
            setActiveConversation(null);
        }
    };

    const handleNewMessage = async () => {
        console.log(recipientNumber);
        console.log(newMessage);


        if (recipientNumber.trim() !== '' && newMessage.trim() !== '') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'sendSMS');
                formData.append('phoneNumberId', selectedPhoneId);
                formData.append('conversationPhoneNumber', recipientNumber);
                formData.append('smsMessage', newMessage);
                const response = await axios.post(apiUrl, formData);
                fetchConversationsFromAPI(selectedPhoneId);
                setOutgoingPhoneNumber('');
                setNewMessage('');
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    const fetchCompleteConversation = async (phoneNumber) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getConversation');
            formData.append('conversationPhoneNumber', phoneNumber);
            const response = await axios.post(apiUrl, formData);
            const completeConversation = Object.values(response.data.data ?? []);
            setCurrentConversation(completeConversation);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSelectConversation = (conversation) => {
        // Logic to set active conversation and fetch messages
        setActiveConversation(conversation.id); 
        setOutgoingPhoneNumber(conversation.from);
        fetchCompleteConversation(conversation.from);
        
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('action', 'sendSMS');
                formData.append('phoneNumberId', selectedPhoneId);
                formData.append('conversationPhoneNumber', outgoingPhoneNumber);
                formData.append('smsMessage', newMessage);
                const response = await axios.post(apiUrl, formData);
                fetchCompleteConversation(outgoingPhoneNumber);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
            setNewMessage('');
        }
    };

    return (
        <div className='content_container'>
            <div id="incomingContainer">
                <CommunicationTypeSelector 
                    showCommunicationTypes={showCommunicationTypes} 
                    selectedCommunicationType={selectedCommunicationType}
                    onSelectCommunicationType={handleSelectCommunicationType}
                />
                <h3>Select Phone Number for Transcripts</h3>
                <PhoneNumberSelector 
                    userPhoneNumbers={userPhoneNumbers} 
                    selectedPhoneNumber={selectedPhoneNumber} 
                    onSelectPhoneNumber={handleSelectPhoneNumber}
                />
                {selectedCommunicationType === 'sms' && (
                    <MessageManager 
                        conversations={conversations}
                        activeConversation={activeConversation}
                        currentConversation={currentConversation}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        handleSendMessage={handleSendMessage}
                        isNewMessageMode={isNewMessageMode}
                        toggleNewMessageMode={toggleNewMessageMode}
                        handleNewMessage={handleNewMessage}
                        outgoingPhoneNumber={outgoingPhoneNumber}
                        setOutgoingPhoneNumber={setOutgoingPhoneNumber}
                        fetchCompleteConversation={fetchCompleteConversation}
                        handleSelectConversation={handleSelectConversation}
                        recipientNumber={recipientNumber} // Pass recipient number
                        setRecipientNumber={setRecipientNumber} // Pass setter for recipient number                    
                    />
                )}
                {selectedCommunicationType === 'calls' && (
                    <div className="phone-call-transcriptions">
                        <PhoneCallTranscriptions phoneId={selectedPhoneId} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Incoming;