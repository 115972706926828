import React, { useContext, useState} from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate} from "react-router-dom";
import axios from 'axios';
import '../css/auth.css';

const CreateAccount = () => {
    const { apiUrl, loading, setLoading, hmac, setHmac } = useContext(AppContext);
    const [createAccountError, setCreateAccountError] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setphone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const navigate = useNavigate();

    const setupSession = (hmac) => {
        setHmac(hmac);
        sessionStorage.setItem('hmac_key', hmac);
    }

    const handleCreateAccount = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            if (password !== retypePassword) {
                throw new Error("Passwords do not match");
            }

            let formData = new FormData();
            formData.append('action', 'createAccount');
            formData.append('businessName', businessName);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('password', password);            

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setupSession(response.data.data);
                navigate('/');
            } else {
                const errorMessage = response.data.message;
                setCreateAccountError(errorMessage); // Set error message to state
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="create-account-form">
                <form onSubmit={handleCreateAccount}>
                    <h4>Create Your Account</h4>
                    <p className="risk-free-info">Creating an account is completely risk-free. Enjoy full access to all features without any obligation or hidden fees. You can cancel anytime!</p>
                    <input
                        type="text"
                        placeholder="Business Name"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                    <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Retype Password"
                        value={retypePassword}
                        onChange={(e) => setRetypePassword(e.target.value)}
                        required
                    />
                    <button type="submit">Create Account</button>
                </form>
            </div>
            <div className="errors">
                {createAccountError && <div className="error-message">{createAccountError}</div>}
            </div>            
        </>
    );
};

export default CreateAccount;