import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from "react-router-dom";
import CreateAccount from './CreateAccount';
import LoginForm from './LoginForm';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import '../css/auth.css';

const AccountForm = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkHmac = () => {
            const hmac = sessionStorage.getItem('hmac_key');
            if (hmac) {
                navigate('/');
            }
        }
        checkHmac();
    }, [navigate]);

    return (
        <>
            <Header />
            <div className="account-form">
                <div className="form-container">
                    <div className="account-section">
                        <LoginForm />
                        <CreateAccount />
                    </div>

                    <div className="company-info">
                        <h3>About AstroVoice</h3>
                        <p>AstroVoice is a groundbreaking SaaS solution, founded in 2024, that has already revolutionized customer connections across various industries. Our platform leverages advanced technology to streamline communication, enhance user experiences, and ultimately drive business growth. Discover our suite of powerful features that make exceptional customer engagement effortless.</p>
                    </div>
                    
                    {/* <div className="trust-signals">
                        <h3>Why Choose AstroVoice?</h3>
                        <p>Our platform is designed to streamline your operations and enhance user experiences. See what our customers are saying!</p>
                        {/* Testimonials or client logos can be added here 
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AccountForm;