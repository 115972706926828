import React, { useContext, useEffect, useState, useCallback, useRef} from 'react';
import { AppContext } from '../../AppContext';
import Modal from 'react-modal';
import axios from 'axios';
import Messages from './Messages';
import { MyPhoneNumbers } from './MyPhoneNumbers';
import { Tooltip } from 'react-tooltip';

import '../css/main.css';
import '../css/campaign.css';

const Campaigns = () => {
    const { apiUrl, hmac, loading, setLoading } = useContext(AppContext);
    const [campaigns, setCampaigns] = useState([]);
    const [showMessages, setShowMessages] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [phoneNumbers, setPhoneNumbers] = useState([]);


    const handleOpenMessages = (campaign) => {
        setSelectedCampaign(campaign);
        setShowMessages(true);
    };

    useEffect(() => {
        getCampaigns();
        getUserPhoneNumbers();
    }, []);

    const getUserPhoneNumbers = async () => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listMyPhoneNumbers');

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                const rd = response.data.data;
                setPhoneNumbers(rd);
            } else {
                console.log('No phone numbers found.');
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const getCampaigns = async () => {
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listCampaigns');
            const response = await axios.post(apiUrl, formData); 
            if (response.data.status == 'success') {
                setCampaigns(response.data.data);
            } else {
                console.log('no campaigns found');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        // Pass phoneNumbers to the ListCampaigns component
        <div className='content_container'>
            {!showMessages && (
                <ListCampaigns 
                    handleOpenMessages={handleOpenMessages} 
                    campaigns={campaigns} 
                    getCampaigns={getCampaigns} 
                    setSelectedCampaign={setSelectedCampaign} 
                    selectedCampaign={selectedCampaign} 
                    setShowMessages={setShowMessages} 
                    phoneNumbers={phoneNumbers}
                />
            )}
            {showMessages && (
                <Messages 
                    campaign={selectedCampaign} 
                    setShowMessages={setShowMessages} 
                />
            )}
        </div>
    );
};

const ListCampaigns = ({campaigns, getCampaigns, selectedCampaign, setSelectedCampaign, handleOpenMessages, phoneNumbers }) => {
    const { apiUrl, setLoading, hmac } = useContext(AppContext);
    const [isEditMode, setIsEditMode] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    

    const handleOpenCreateModal = () => {
        setModalVisible(false);
        setModalVisible(true);
        setIsEditMode(false);
    }

    const handleOpenEditModal = async (campaign) => {
        setModalVisible(false);
        setSelectedCampaign(campaign);
        setIsEditMode(true);

        // Delay setting the modalVisible to true to allow the previous modal to unmount
        setTimeout(() => {
            setModalVisible(true);
        }, 100);
    };

    const handleCloseModal = () => {
        setSelectedCampaign(null);
        setIsEditMode(false);
        setModalVisible(false);
    };

    const handleDeleteCampaign = async (campaignId) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'deleteCampaign');
            formData.append('campaignId', campaignId);

            const response = await axios.post(apiUrl, formData);
            getCampaigns();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <div>
            <button onClick={() => handleOpenCreateModal()} className='newBtn'>Create Campaign</button>

            <div className='campaignContainer'>
                {campaigns && campaigns.length > 0 ? (campaigns?.map(campaign =>
                    <div key={campaign.campaign_id} className="eachCampaign">
                        <h3>{campaign.campaign_name}</h3>
                        <p data-tip={campaign.campaign_description}>
                            {campaign.campaign_description.length > 100
                            ? campaign.campaign_description.substring(0, 100) + "..."
                            : campaign.campaign_description}
                        </p>

                        <Tooltip effect="solid" place="top" />

                        <div className="campaignBtns">
                            <button onClick={async () => {
                                handleOpenMessages(campaign)
                            }}>Messages</button>

                            <button onClick={async () => {
                                setSelectedCampaign(campaign);
                                handleOpenEditModal(campaign)
                            }}>Edit</button>

                            <button onClick={() => {
                                const confirmDelete = window.confirm("Are you sure you want to delete this campaign?");
                                if (confirmDelete) {
                                    handleDeleteCampaign(campaign.campaign_id);
                                }
                            }}>Delete</button>
                        </div>
                    </div>
                )):(
                    <p>You don't currently have any SMS Campaigns yet.</p>
                )}

                {modalVisible && (
                    <Modal isOpen={modalVisible} onRequestClose={handleCloseModal}>
                        <button type="button" className="Modal-close" onClick={handleCloseModal}>X</button>
                        {isEditMode ? (
                            <EditCampaign campaign={selectedCampaign}  phoneNumbers={phoneNumbers} handleCloseModal={handleCloseModal} getCampaigns={getCampaigns} />
                        ) : (
                            <CreateCampaign  phoneNumbers={phoneNumbers} handleCloseModal={handleCloseModal} getCampaigns={getCampaigns} />
                        )}
                    </Modal>
                )}


                {/* {messagesModalVisible && selectedCampaign && ( */}
                    {/* <Modal isOpen={messagesModalVisible} onRequestClose={handleCloseMessagesModal}> */}
                        

                    {/* </Modal> */}
                {/* )} */}
            </div>
        </div>            
    )

}
  

const CreateCampaign = ({ handleCloseModal, getCampaigns, phoneNumbers}) => {
    const { apiUrl, hmac, selectedGroups, setSelectedGroups, setLoading, isSavable} = useContext(AppContext);
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        clearFields();
    }, []);
    
    const clearFields = () => {
        setCampaignName('');
        setCampaignDescription('');
        setStartDate('');
        setEndDate('');
        setSelectedGroups([]);
    }

    const tomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow.toISOString().split('T')[0];
    }

    // const handleMinimums = () => {
    //     const startDate = stDate.current?.value;
      
    //     if (startDate) {
    //       // Calculate minimum end date
    //       const newMinEndDate = new Date(startDate);
    //       newMinEndDate.setDate(newMinEndDate.getDate() + 1); // Add one day to the start date
      
    //       // Update the minimum end date
    //       edDate.current.min = newMinEndDate.toISOString().split('T')[0];
      
    //       // Reset the end date if it is earlier than the new minimum end date
    //       const endDate = edDate.current?.value;
    //       if (endDate && endDate < newMinEndDate.toISOString().split('T')[0]) {
    //         setEndDate(newMinEndDate.toISOString().split('T')[0]);
    //       }
    //     }
    //   };


    const handleCreateCampaign = async () => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'addCampaign');
            formData.append('campaignName', campaignName);
            formData.append('campaignDescription', campaignDescription);
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('campaignContactGroups', JSON.stringify(selectedGroups));
            formData.append('phoneNumberId', selectedPhoneNumber); // Add phone number
            const response = await axios.post(apiUrl, formData);
            clearFields();
            await getCampaigns();
            handleCloseModal();
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='modalContainer'>
            <h3>Create Campaign</h3>
            <form >
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" placeholder="Enter campaign name" defaultValue={campaignName} onChange={(e) => setCampaignName(e.target.value)} />

                <label htmlFor="description">Description:</label>
                <textarea id="description" placeholder="Enter campaign description - this is where you'll include relevant information that you want our AI systems to incorporate in to your campaign." defaultValue={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)}></textarea>

                <label htmlFor="phoneNumber">Select Phone Number:</label>
                <select id="phoneNumber" value={selectedPhoneNumber} onChange={(e) => setSelectedPhoneNumber(e.target.value)}>
                    <option value="">Select a Phone number</option>
                    {phoneNumbers.map((phoneNumber) => (
                        <option key={phoneNumber.user_phone_id} value={phoneNumber.user_phone_id}>
                            {phoneNumber.phone_number}
                        </option>
                    ))}
                </select>                

                <label htmlFor="startDate">Start Date:</label>
                <input type="date" id="startDate" placeholder="Select campaign start date" defaultValue={startDate} min={tomorrowDate()} onChange={(e) => { setStartDate(e.target.value); }} />

                <label htmlFor="endDate">End Date:</label>
                <input type="date" id="endDate" placeholder="Select campaign end date" defaultValue={endDate} onChange={(e) => setEndDate(e.target.value)} />

                <CampaignContactGroups id="contactGroups" clearFields={clearFields} />

                <div className="saveAndCancelBtns">
                    <button type="button" style={{ cursor: (!campaignName || !campaignDescription || !startDate || !endDate || !selectedPhoneNumber || isSavable) ? 'not-allowed' : 'pointer' }} disabled={!campaignName || !campaignDescription || !startDate || !endDate || !selectedPhoneNumber || isSavable} onClick={handleCreateCampaign}>Save</button>
                    <button type="button" onClick={() => {clearFields(); handleCloseModal()}}>Cancel</button>
                </div>
            </form>
        </div>
    );
};


const EditCampaign = ({ campaign, handleCloseModal, getCampaigns, phoneNumbers}) => {
    const { apiUrl, hmac, selectedGroups, setLoading } = useContext(AppContext);
    const [campaignId, setCampaignId] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
   
    useEffect(() => { 
        if (campaign) {
            setCampaignId(campaign.campaign_id);
            setCampaignName(campaign.campaign_name);
            setCampaignDescription(campaign.campaign_description);
            setStartDate(campaign.campaign_start_date);
            setEndDate(campaign.campaign_end_date);
            setSelectedPhoneNumber(campaign.user_phone_id);
        }
        
    }, []);

    const handleEditCampaign = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'modifyCampaign');
            formData.append('campaignId', campaignId);
            formData.append('campaignName', campaignName);
            formData.append('campaignDescription', campaignDescription);
            formData.append('phoneNumberId', selectedPhoneNumber); // Add phone number
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('campaignContactGroups', JSON.stringify(selectedGroups));

            const response = await axios.post(apiUrl, formData);

            // Clear form fields and contact groups after successful update]
            setCampaignName('');
            setCampaignDescription('');
            setStartDate('');
            setEndDate('');
            getCampaigns();
            handleCloseModal();
            
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (
        <div className='modalContainer'>
            <div>
                <h3>Edit Campaign</h3>
                <form onSubmit={handleEditCampaign}>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" defaultValue={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                    
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" defaultValue={campaignDescription ? campaignDescription : ''} onChange={(e) => setCampaignDescription(e.target.value)}></textarea>
                    
                    <label htmlFor="phoneNumber">Select Phone Number:</label>
                    <select id="phoneNumber" value={selectedPhoneNumber} onChange={(e) => setSelectedPhoneNumber(e.target.value)}>
                        <option value="">Select a Phone number</option>
                        {phoneNumbers.map((phoneNumber) => (
                            <option key={phoneNumber.user_phone_id} value={phoneNumber.user_phone_id}>
                                {phoneNumber.phone_number}
                            </option>
                        ))}
                    </select>                    

                    <label htmlFor="startDate">Start Date:</label>
                    <input type="date" id="startDate" defaultValue={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    
                    <label htmlFor="endDate">End Date:</label>
                    <input type="date" id="endDate" defaultValue={endDate} onChange={(e) => setEndDate(e.target.value)} />

                    <CampaignContactGroups id="contactGroups" campaign={campaign} />

                    <div className="saveAndCancelBtns">
                        <button type="button" style={{ cursor: (!campaignName || !campaignDescription || !startDate || !endDate || !selectedPhoneNumber) ? 'not-allowed' : 'pointer' }} disabled={!campaignName || !campaignDescription || !startDate || !endDate || !selectedPhoneNumber} onClick={handleEditCampaign}>Save</button>
                        <button type="button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const CampaignContactGroups = (campaign) => {
    const { apiUrl, availableGroups, setAvailableGroups, selectedGroups, setSelectedGroups, hmac, setLoading, setIsSavable } = useContext(AppContext);

    useEffect(() => {
        setLoading(true);
        const runitup = async () => {
            let filteredGroups = [];

            const getAvailableGroups = async (selectedGs) => {
                try {
                    let formData = new FormData();
                    formData.append('userHmacId', hmac);
                    formData.append('action', 'listGroups');
                
                    const response = await axios.post(apiUrl, formData);
                    if (response.data.status === 'success') {
                        const response_data = response.data.data;
                        // Filter out the selected groups from the available groups
                        filteredGroups = await response_data.filter(group => {
                            return !selectedGs?.some(selectedG => {
                                return selectedG.contact_group_id === group.contact_group_id;
                            });
                        });
                        setAvailableGroups(filteredGroups);
                    } else {
                        setAvailableGroups([]);
                        setIsSavable(false);
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            const getSelectedGroups = async (campaign) => {
                if (!campaign?.campaign) {
                    return;
                }
        
                try {
                    let formData = new FormData();
                    formData.append('userHmacId', hmac);
                    formData.append('action', 'listSelectedGroups');
                    formData.append('campaignId', campaign.campaign.campaign_id);
        
                    const response = await axios.post(apiUrl, formData);
                    if (response.data.status === 'success') {
                        const response_data = response.data.data;
                        return response_data;
                    } else {
                        return [];
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            const selectedG = await getSelectedGroups(campaign)
            setSelectedGroups(selectedG);
            await getAvailableGroups(selectedG);
            setLoading(false);
        };

        runitup();
    }, []);

    const addToGroup = useCallback((contactGroup) => {
        if (!selectedGroups || !Symbol.iterator in Object(selectedGroups)) {
          setSelectedGroups([contactGroup]);
        } else {
          const isDuplicate = selectedGroups.some((group) => group.contact_group_id === contactGroup.contact_group_id);
          setSelectedGroups(isDuplicate ? selectedGroups : [...selectedGroups, contactGroup]);
        }

        const newAvailableGroups = availableGroups.filter((group) => group.contact_group_id !== contactGroup.contact_group_id);
        setAvailableGroups(newAvailableGroups);        ;
    }, [selectedGroups, availableGroups]);

    const removeFromAvailableGroups = (contactGroup) => {
        const newAvailableGroups = availableGroups.filter((group) => group.contact_group_id !== contactGroup.contact_group_id);

        setAvailableGroups(newAvailableGroups);
    };

    const removeFromSelectedGroups = (contactGroup) => {
        const newSelectedGroups = selectedGroups.filter(
            (group) => group.contact_group_id !== contactGroup.contact_group_id
        );
        setSelectedGroups(newSelectedGroups);
        addToAvailableGroups(contactGroup);
    };

    const addToAvailableGroups = (contactGroup) => {
        setAvailableGroups([...availableGroups, contactGroup]);
    };


    return (
        <div className='campaignGroups'>
            <div className='AvailableContactGroups'>
                <h1>Available Contacts</h1>
                {availableGroups.map((contactGroup, index) => (
                    <div className='AvailableGroup' key={index}>
                        <span>{contactGroup.contact_group_name}</span>
                        <button type='button' onClick={() => { addToGroup(contactGroup) }}>
                            use
                        </button>
                    </div>
                ))}
            </div>

            <div className='SelectedContactGroups'>
                <h1>Selected Groups for your campaign</h1>
                {selectedGroups?.map((selectedGroup, index) => (
                    <div className='SelectedGroup' key={index}>
                        <span>{selectedGroup.contact_group_name}</span>
                        <button type="button" onClick={() => removeFromSelectedGroups(selectedGroup)}>Remove</button>
                    </div>
                ))}
            </div>
        </div>
    );
};
  

export default Campaigns;