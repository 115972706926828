import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import EscalationContactsModal from './EscalationContactsModal'; // Assuming you have a Modal component
import AssistantKnowledgebaseModal from './AssistantKnowledgebaseModal'; // Assuming you have a Modal component for managing assistant knowledgebases.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

import '../css/assistantphone.css';


const AssistantPhone = ({ phoneNumber }) => {
    const { apiUrl, hmac, setLoading, setNewView, selectedContactPhone, setSelectedContactPhone } = useContext(AppContext);

    const [assistantKnowledgebases, setAssistantKnowledgebases] = useState([]); // New state to hold multiple assistant knowledgebases
    const [isCollapsed, setIsCollapsed] = useState(true);
    
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [escalationContact, setEscalationContact] = useState(null);
    const [showEscalationContactsModal, setShowEscalationContactsModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [prompt, setPrompt] = useState(phoneNumber.prompt);
    const [assistantName, setAssistantName] = useState(phoneNumber.assistant_name);
    const [assistantType, setAssistantType] = useState(phoneNumber.assistant_type);
    const [selectedLanguages, setSelectedLanguages] = useState(phoneNumber.assistant_language ? JSON.parse(phoneNumber.assistant_language) : []);
      

    const [assistantDescription, setAssistantDescription] = useState(phoneNumber.assistant_description);
    const [showAssistantKnowledgebaseModal, setShowAssistantKnowledgebaseModal] = useState(false);
    const [assistantImageURL, setAssistantImageURL] = useState(phoneNumber.assistant_image_url);
    const [assistantEnabled, setAssistantEnabled] = useState(phoneNumber.assistant_enabled);
    const [assistantSettings, setAssistantSettings] = useState(phoneNumber.assistant_settings);
    const [assistantVoice, setAssistantVoice] = useState(phoneNumber.assistant_voice ?? 'shimmer');
    const [kbId, setKbId] = useState(null);
    const [knowledgebaseType, setKnowledgeBaseType] = useState('');
    const [knowledgebaseText, setKnowledgeBaseText] = useState('');
    const [files, setFiles] = useState([]);

    const [escalationContacts, setEscalationContacts] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState("");
    const languages = [
        "Afrikaans", "Arabic", "Armenian", "Azerbaijani", "Belarusian", "Bosnian", "Bulgarian", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "English", "Estonian", "Finnish", "French", "Galician", "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kannada", "Kazakh", "Korean", "Latvian", "Lithuanian", "Macedonian", "Malay", "Marathi", "Maori", "Nepali", "Norwegian", "Persian", "Polish", "Portuguese", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish", "Swahili", "Swedish", "Tagalog", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Welsh"
    ];
    
    useEffect(() => {
        getEscalationContacts(phoneNumber);
    }, []);

    useEffect(() => {
        getAssistantKnowledgebases(phoneNumber.user_phone_id); // Fetch assistant knowledgebases
    }, [phoneNumber]); 

    useEffect(() => {
        if (escalationContact?.escalation_phone_number) {
            validatePhoneNumber(escalationContact.escalation_phone_number);
        }
    }, [escalationContact]);

    const toggleAssistantKnowledgebaseModal = () => {
        setShowAssistantKnowledgebaseModal(!showAssistantKnowledgebaseModal);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const validatePhoneNumber = (value) => {
        const phoneNumberPattern = /^(\+\d{1,3}\s)?\(\d{3}\)\s\d{3}-\d{4}$/;
        // let res = phoneNumberPattern.test(value);
        setPhoneNumberValid(phoneNumberPattern.test(value));
    };

    const getAssistantKnowledgebases = async (userPhoneId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getAssistantKnowledgebases');
            formData.append('userPhoneId', userPhoneId);
    
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                setAssistantKnowledgebases(response.data.data); 
            }
            else {
                setAssistantKnowledgebases(null); 
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const getEscalationContacts = async (phoneNumber) => {
        try {
            setLoading(true);
            const escalationPhoneNumberId = phoneNumber.user_phone_id;       
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'getEscalationContacts');
            formData.append('escalationPhoneNumberId', escalationPhoneNumberId);
        
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                const escalationContacts = response.data.data;
                setEscalationContacts(escalationContacts);
            } else {
                // Handle error response
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const voiceTypes = [
        {
          name: "Alloy",
          description: "(Male) A strong and powerful voice",
        },
        {
          name: "Echo",
          description: "(Female) A clear and echoing voice",
        },
        {
          name: "Fable",
          description: "(Female) A soothing and storytelling voice",
        },
        {
          name: "Onyx",
          description: "(Male) A mysterious and deep voice",
        },
        {
          name: "Nova",
          description: "(Female) A bright and energetic voice",
        },
        {
          name: "Shimmer",
          description: "(Female) A sparkling and enchanting voice",
        },
    ];
      
    const assistantTypes = [
        "All-in-One Business Assistant",
        "Customer Experience Assistant",
        "Digital Marketing Assistant",
        "E-commerce Assistant",
        "Event Management Assistant",
        "General Purpose Assistant",
        "Health and Wellness Assistant",
        "Intelligent Sales Assistant",
        "Personal Productivity Assistant",
        "Personalized Learning Assistant",
        "Relationship Management Assistant",
        "Seamless Communication Assistant",
        "Technical Solutions Assistant",
        "Virtual Executive Assistant",
        "Virtual Operations Assistant"
    ];

    const handleAssistantUpdate = async () => {
        if (!assistantName || !assistantVoice || !assistantType || !selectedLanguages.length || !assistantDescription) {
            alert('Please fill in all fields before submitting.');
            return;
        }

        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'updatePhonePrompt');
            formData.append('phoneNumberId', phoneNumber.user_phone_id);

            if (assistantVoice !== null && assistantVoice !== undefined) {
                formData.append('assistantVoice', assistantVoice);
            }

            if (assistantName !== null && assistantName !== undefined) {
                formData.append('assistantName', assistantName);
            }
            if (assistantType !== null && assistantType !== undefined) {
                formData.append('assistantType', assistantType);
            }
            if (selectedLanguages !== null && selectedLanguages !== undefined) {
                formData.append('selectedLanguages', JSON.stringify(selectedLanguages));
            }
            if (assistantDescription !== null && assistantDescription !== undefined) {
                formData.append('assistantDescription', assistantDescription);
            }

            formData.append('assistantKnowledgebases', JSON.stringify(assistantKnowledgebases));
            formData.append('phonePromptText', prompt);
            const response = await axios.post(apiUrl, formData);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };
    
    const toggleModal = () => {
        setShowEscalationContactsModal(!showEscalationContactsModal);
    };

    const handleLanguageClick = (language) => {
        if (selectedLanguages?.includes(language)) {
          setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
        } else {
          setSelectedLanguages([...selectedLanguages, language]);
        }
    }  

    const handleViewConversations = () => {
        setSelectedContactPhone(phoneNumber);
        setNewView('Incoming');
    };

    const handleEditContact = (contact) => {
        setIsEditing(true);
        validatePhoneNumber(contact.escalation_phone_number);
        setEscalationContact(contact);
        toggleModal();
    };    

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
    
        if (name === "escalation_phone_number") {
            // Remove all non-digit characters from the input value
            const formattedValue = value.replace(/\D/g, '');
    
            // Format the phone number in the traditional manner (e.g., +country code (123) 456-7890)
            const formattedPhoneNumber = formattedValue.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
    
            const updatedContact = { ...escalationContact, [name]: formattedPhoneNumber };
            setEscalationContact(updatedContact);
        } else {
            const updatedContact = { ...escalationContact, [name]: value };
            setEscalationContact(updatedContact);
        }
    };

    const handleAddEscalationContact = () => {
        setIsEditing(false);
        setEscalationContact("");
        toggleModal();        
    }

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        const value = e.target.checked ? '1' : '0';
      
        setEscalationContact((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };
    
    const handleEscalationSave = async (e) => {
        e.preventDefault();
    
        try {
            setLoading(true);
            
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            if (isEditing) {
                // Perform API call to update the existing contact
                formData.append('action', 'updateEscalationPhoneNumber');
                formData.append('escalationId', escalationContact.escalation_id);
    
            } else {
                // Perform form validation and save the escalation contact
                formData.append('action', 'createEscalationPhoneNumber');
                formData.append('escalationPhoneNumberId', phoneNumber.user_phone_id);
            }
    
            formData.append('escalationFirstName', escalationContact.escalation_first_name); // Add first name
            formData.append('escalationLastName', escalationContact.escalation_last_name);   // Add last name
            formData.append('escalationTitle', escalationContact.escalation_title);
            formData.append('category', escalationContact.category);
            formData.append('escalationEmail', escalationContact.escalation_email);
            formData.append('escalationPhoneNumber', escalationContact.escalation_phone_number);
            formData.append('acceptsTextMessage', escalationContact.accepts_text_message ? '1' : '0');
    
            // Perform the API call
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            toggleModal();
            
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleDeleteRoutine = (contact) => {
        handleDeleteContact(contact);
        getEscalationContacts(phoneNumber);
    }

    const handleDeleteContact = async (escalationContact) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'deleteEscalationContact');
            formData.append('escalationId', escalationContact.escalation_id);
        
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                let successMsg = response.data.data;
                console.log(successMsg);
            } else {
                // Handle error response
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };


    function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the phone number using regex
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    
        // Remove the country code if present
        const strippedPhoneNumber = cleanedPhoneNumber.replace(/^1/, "");
    
        // Format the phone number in traditional format
        const formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    
        return formattedPhoneNumber;
    }

    const handlePromptChange = (event) => {
        const value = event.target.value;
    
        // Prevent URLs from being entered
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if (!urlPattern.test(value)) {
            setPrompt(value);
        } else {
            alert('URLs are not allowed in this field.');
        }
    };    

    const handleAssistantDescriptionChange = (event) => {
        const value = event.target.value;
    
        // Prevent URLs from being entered
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if (!urlPattern.test(value)) {
            setAssistantDescription(value);
        } else {
            alert('URLs are not allowed in this field.');
        }
    }

    const escalationSaveAndRefresh = async (e) => {
        if (!phoneNumberValid) {
            alert('you need a valid phone number');
            return ;
        }

        await handleEscalationSave(e);
        getEscalationContacts(phoneNumber);
    }

    const handleSaveKnowledge = async () => {
        if (knowledgebaseType && knowledgebaseText) {
            const knowledgebaseData = {
                knowledgebase_type: knowledgebaseType,
                knowledgebase_text: knowledgebaseText,
                files: files,
            };
    
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('userHmacId', hmac);
                formData.append('phoneNumberId', phoneNumber.user_phone_id);
                formData.append('action', isEditMode ? 'updateKnowledgebase' : 'uploadKnowledgebase'); // Update API action
                formData.append('kbId', kbId);
                formData.append('knowledgebaseType', knowledgebaseData.knowledgebase_type);
                formData.append('knowledgebaseText', knowledgebaseData.knowledgebase_text);

                files?.forEach((file) => {
                    formData.append('knowledgebaseFiles[]', file); // Append each file to the form data
                });
    
                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                if (response.data.status === 'success') {
                    getAssistantKnowledgebases(phoneNumber.user_phone_id);
                    toggleAssistantKnowledgebaseModal();
                } else {
                    alert('Failed to upload knowledgebase.'); // Handle failure
                }
            } catch (error) {
                console.error('Error uploading knowledgebase:', error);
                alert('Error uploading knowledgebase.');
            } finally {
                setLoading(false);
            }
        } else {
            alert('Please fill in all fields.');
        }
    };    

    const handleDeleteKBFile = async (kbId, filePath) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'deleteKnowledgebaseFile');
            formData.append('kbId', kbId);
            formData.append('kbFilePath', filePath);
    
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                console.log('File deleted successfully.');
            } else {
                console.error('Failed to delete file.');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditKnowledgebase = (kb) => {
        setKnowledgeBaseType(kb.kb_type);
        setKnowledgeBaseText(kb.kb_text);
        setFiles(kb.files);
        setIsEditMode(true);
        setShowAssistantKnowledgebaseModal(true);
        setKbId(kb.kb_id);
    };
    
    const handleDeleteKnowledgebase = async (kbId) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'deleteKnowledgebase');
            formData.append('kbId', kbId);
            
            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                getAssistantKnowledgebases(phoneNumber.user_phone_id); // Refresh knowledgebases
            } else {
                alert('Failed to delete knowledgebase.'); // Handle failure
            }
        } catch (error) {
            console.error('Error deleting knowledgebase:', error);
            alert('Error deleting knowledgebase.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddKnowledge = async () => {
        setIsEditMode(false);
        setKnowledgeBaseType('');
        setKnowledgeBaseText('');
        setFiles(null);

        setShowAssistantKnowledgebaseModal(true);        
        toggleAssistantKnowledgebaseModal()


    }

    return (
        <div className="each_number">
            <div className="phone-number">
                <span className="assistantPhoneNumber">
                    {formatPhoneNumber(phoneNumber.phone_number)}
                </span>
                <button className="toggle-collapse-btn" onClick={toggleCollapse}>
                    {isCollapsed ? 'Show Settings' : 'Close Settings'}
                </button>                
            </div>
    
            {!isCollapsed && (
                <>
                    <div className="phone-number-actions">
                        {/* Add the input fields for assistant information */}
                        <div className="assistant-fields">
                            <div className="assistant-field">
                                <label className="assistant-label">Choose Your Assistant's Name</label>
                                <input
                                    className="assistant-input"
                                    type="text"
                                    defaultValue={assistantName}
                                    onChange={(event) => setAssistantName(event.target.value)}
                                    placeholder="Enter assistant name"
                                />
                            </div>
                            <div className="assistant-field">
                                <label className="assistant-label">Choose Your Assistant's Voice</label>
                                <select
                                    className="assistant-select"
                                    defaultValue={assistantVoice}
                                    onChange={(event) => setAssistantVoice(event.target.value)}
                                >
                                    <option value="">Select Voice Type</option>
                                    {voiceTypes?.map((type) => (
                                        <option key={type.name} value={type.name}>
                                            {type.name} - {type.description}
                                        </option>
                                    ))}

                                </select>
                            </div>                    
                            <div className="assistant-field">
                                <label className="assistant-label">Choose the Type of Assistant</label>
                                <select
                                    className="assistant-select"
                                    defaultValue={assistantType}
                                    onChange={(event) => setAssistantType(event.target.value)}
                                >
                                    {assistantTypes?.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="assistant-field">
                                <label className="assistant-label">What Language should your Assistant Know ?</label>
                                <div className="language-bubbles">
                                    {languages?.map((language) => (
                                    <div
                                        className={`language-bubble ${selectedLanguages?.includes(language) ? 'selected' : ''}`}
                                        key={language}
                                        onClick={() => handleLanguageClick(language)}
                                    >
                                        {language}
                                    </div>
                                    ))}
                                </div>
                                <div className="selected-languages">
                                    {selectedLanguages?.map((language) => (
                                    <div
                                        className="selected-language"
                                        key={language}
                                        onClick={() => handleLanguageClick(language)}
                                    >
                                        <span className="lang-selected">{language}</span>
                                    </div>
                                    ))}
                                </div>
                            </div>

                            <div className="assistant-field">
                                <label className="assistant-label">Describe Your Assistant (You don't have to be brief...)</label>
                                <textarea
                                    className="assistant-input"
                                    defaultValue={assistantDescription}
                                    onChange={handleAssistantDescriptionChange}
                                    placeholder="Enter assistant description"
                                    rows={15}
                                />
                            </div>
                        </div>

                        <div className="prompt-section">
                            <label className="prompt-label">Define Your Assistant's General Knowledgebase</label>
                            <textarea
                                className="prompt-textarea"
                                defaultValue={prompt}
                                onChange={handlePromptChange}
                                rows={25}
                            />
                        </div>

                        <div className="assistant-knowledgebases-section">
                                {assistantKnowledgebases?.length > 0 ? (<label>Any Additional Knowledge</label>) : (null)}
                                <button onClick={handleAddKnowledge}>Add additional knowledge</button>
                                <div id="knowledgebase-container">
                                    {assistantKnowledgebases?.map((kb) => (
                                        <div key={kb.kb_id} className='knowledge'>
                                            <label>{kb.kb_type}:</label>
                                            <p className='kb_text'>
                                                {kb.kb_text}
                                            </p>
                                            <button onClick={() => handleEditKnowledgebase(kb)}>Edit</button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this knowledgebase?");
                                                if (confirmDelete) {
                                                    handleDeleteKnowledgebase(kb.kb_id);
                                                }
                                            }}>
                                                Delete
                                            </button>                                            

                                            {kb.files && kb.files.length > 0 && (
                                                <div className="knowledgebase-attachment">
                                                    <h6>Attachments:</h6>
                                                    <div>
                                                        {kb.files?.map((file, index) => (
                                                            <div key={index} className="knowledgebase-item">
                                                                <div className="knowledgebase-icon">
                                                                    <FontAwesomeIcon icon={faFileAlt} /> {/* File Icon */}
                                                                    <a
                                                                        className="delete-icon" 
                                                                        onClick={async () => {
                                                                            const confirmDelete = window.confirm("Are you sure you want to delete this file?");
                                                                            if (confirmDelete) {
                                                                                await handleDeleteKBFile(kb.kb_id, file);
                                                                                getAssistantKnowledgebases(phoneNumber.user_phone_id); // Refresh knowledgebases
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTimes} /> {/* Delete Icon */}
                                                                    </a>
                                                                </div>
                                                                <p className="knowledgebase-filename">{file}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>                        

                        <div className="assistant-actions">
                            <button onClick={handleAssistantUpdate}>
                                Update Assistant
                            </button>
                            <button onClick={handleAddEscalationContact}>
                                Add Human Contacts
                            </button>
                        </div>


                        {/* Display existing escalation contacts */}
                        {escalationContacts?.length > 0 && (
                            <div className='escalation-wrap'>
                                <label>Your Human Team</label>
                                <div className="escalation-contacts-container">
                                    {escalationContacts?.map((contact) => (
                                        <div key={contact.escalation_id} className="escalation-contact">
                                            <p><label>{contact.escalation_title}</label></p>
                                            <p>{contact.escalation_last_name}, {contact.escalation_first_name}</p>
                                            <p>{contact.escalation_email}</p>
                                            <p><label>{formatPhoneNumber(contact.escalation_phone_number)}</label></p>
                                            <p><label>Accepts Text Messages:</label> {contact.accepts_text_message == 1 ? 'Yes' : 'No'}</p>
                                            <br />
                                            <button onClick={() => handleEditContact(contact)} className="newBtn">
                                                Edit Contact
                                            </button>
                                            <button onClick={() => {
                                                const confirmDelete = window.confirm("Are you sure you want to delete this Escalation Contact?");
                                                if (confirmDelete) {
                                                    setEscalationContact(contact);
                                                    handleDeleteRoutine(contact);
                                                }
                                            }} className="newBtn">
                                                Delete Contact
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                    </div>
                    
                    {showEscalationContactsModal && (
                        <form onSubmit={escalationSaveAndRefresh}>
                            <EscalationContactsModal 
                                onClose={toggleModal}
                                escalationContact={escalationContact}
                                setEscalationContact={setEscalationContact}
                                selectedTitle={selectedTitle}
                                setSelectedTitle={setSelectedTitle}
                                handleFieldChange={handleFieldChange}
                                handleCheckboxChange={handleCheckboxChange}
                                phoneNumberValid={phoneNumberValid}
                                setPhoneNumberValid={setPhoneNumberValid}
                                escalationSaveAndRefresh={escalationSaveAndRefresh}
                                validatePhoneNumber={validatePhoneNumber}
                                formatPhoneNumber={formatPhoneNumber}
                            >
                                <label>
                                    Accepts Text Messages
                                    <input
                                        type="checkbox"
                                        name="acceptsTextMessage"
                                        checked={escalationContact.accepts_text_message === '1'}
                                        onChange={handleCheckboxChange}
                                    />
                                </label>
                                <button type="submit">Save</button>
                            </EscalationContactsModal>
                        </form>
                    )}

                    {showAssistantKnowledgebaseModal && (
                        <AssistantKnowledgebaseModal 
                            onClose={toggleAssistantKnowledgebaseModal}
                            setAssistantKnowledgebases={setAssistantKnowledgebases}
                            getAssistantKnowledgebases={getAssistantKnowledgebases}
                            phoneNumber={phoneNumber}
                            knowledgebaseType={knowledgebaseType}
                            setKnowledgeBaseType={setKnowledgeBaseType}
                            knowledgebaseText={knowledgebaseText}
                            setKnowledgeBaseText={setKnowledgeBaseText}
                            files={files}
                            setFiles={setFiles}
                            isEditMode={isEditMode}
                            handleSaveKnowledge={handleSaveKnowledge}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default AssistantPhone;