import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuestionnaireModal from './QuestionnaireModal'; // Import the modal
import './Pricing.css'; // Add your custom styles here

const Pricing = () => {
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const navi = useNavigate();

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <section id="pricing-section" className="pricing-section">
            <h2 className="pricing-title">Elevate Your Communication</h2>
            <p className="pricing-subtitle">Tailored plans designed to meet your increasing needs.</p>
            
            <div className="pricing-plans">
                <div className="pricing-card">
                    <h3 className="plan-title">Starter Combined Plan</h3>
                    <p className="plan-price">Call for Pricing</p>
                    <ul className="plan-features">
                        <li>1 Voice Agent</li>
                        <li>1 Workspace</li>
                        <li>200 Voice Minutes</li>
                        <li>1,000 SMS Messages</li>
                    </ul>
                    <button className="plan-button" onClick={openModal}>Discover More</button>
                </div>

                <div className="pricing-card">
                    <h3 className="plan-title">Professional Combined Plan</h3>
                    <p className="plan-price">Call for Pricing</p>
                    <ul className="plan-features">
                        <li>1 Voice Agent</li>
                        <li>1 Workspace</li>
                        <li>500 Voice Minutes</li>
                        <li>3,000 SMS Messages</li>
                    </ul>
                    <button className="plan-button" onClick={openModal}>Elevate Your Options</button>
                </div>

                <div className="pricing-card">
                    <h3 className="plan-title">Agency Combined Plan</h3>
                    <p className="plan-price">Call for Pricing</p>
                    <ul className="plan-features">
                        <li>Unlimited Voice Agents</li>
                        <li>Unlimited Workspaces</li>
                        <li>1,200 Voice Minutes</li>
                        <li>10,000 SMS Messages</li>
                    </ul>
                    <button className="plan-button" onClick={openModal}>Experience Excellence</button>
                </div>
            </div>

            <div className="pricing-note">
                <p>All plans provide additional options for customization and enhancements, ensuring a perfect fit for your business needs.</p>
            </div>

            <div className='questionnaire-modal'>
                <QuestionnaireModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
        </section>
    );
}

export default Pricing;