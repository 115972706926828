import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate} from "react-router-dom";
import Header from '../jsx/Header';
import Footer from '../jsx/Footer';
import Sidebar from './Sidebar';
import Contacts from './Contacts';
import Campaigns from './Campaigns';
import PhoneNumbers from './PhoneNumbers';
import Onboarding from './Onboarding';
import Incoming from './Incoming';
import Communications from './Communications';
import Astrotext from './Astrotext';
import Astrovoice from './Astrovoice';
import OutgoingCalls from './OutgoingCalls';
import CombinedCommunicationTimeline from './CombinedCommunicationTimeline';
import Loader from './Loader';

import '../css/main.css';
import '../css/responsive.css';
import '../css/loader.css';

const Main = () => {
    const navigate = useNavigate();
    const { setHmac, loading, newView} = useContext(AppContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [contacts] = useState([]);
    

    useEffect(() => {
        // const loggedIn = hmac ? true : false;
        const loggedIn = sessionStorage.getItem("hmac_key");
        if (!loggedIn) {
            setIsLoggedIn(false);
            navigate("/auth");
        } else {
            setHmac(loggedIn);
        }
    });    
    
    return (
        <>
            <Header />
                <div id="container">
                    {loading && (<div className="loader-container">
                        <Loader />
                    </div>
                    )}

                    <Sidebar />

                    <div className="Main">
                        {newView === 'Astrotext' && (
                            <>
                                <Astrotext />
                            </>
                        )}
                        {newView === 'Astrovoice' && (
                            <>
                                <Astrovoice />
                            </>
                        )}                
                        {newView === 'Contacts' && (
                            <>
                                <Contacts contacts={contacts} />
                            </>
                        )}
                        {newView === 'Campaigns' && (
                            <>
                                <Campaigns />
                            </>
                        )}
                        {newView === 'PhoneNumbers' && (
                            <>
                                <PhoneNumbers />
                            </>
                        )}
                        {newView === 'Onboarding' && (
                            <>
                                <Onboarding />
                            </>
                        )}
                        {newView === 'Communications' && (
                            <>
                                <Communications />
                            </>
                        )}
                        {newView === 'Incoming' && (
                            <>
                                <Incoming />
                            </>
                        )}
                        {newView === 'CombinedCommunicationTimeline' && (
                            <>
                                <CombinedCommunicationTimeline />
                            </>
                        )}
                        {newView === 'OutgoingCalls' && (
                            <>
                                <OutgoingCalls />
                            </>
                        )}

                    </div>
                </div>
            {/* <Footer /> */}
        </>
    );
};

export default Main;