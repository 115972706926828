import React, { useState } from 'react';
import './LandingFooter.css'; // You can create your own styles for the footer
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const LandingFooter = () => {
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    const handlePrivacyModalToggle = () => {
        setShowPrivacyModal(!showPrivacyModal);
    };

    const handleTermsModalToggle = () => {
        setShowTermsModal(!showTermsModal);
    };

    return (
        <footer className="landing-footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <img src={process.env.PUBLIC_URL + '/final_logo.png'} alt="AstroVoice Logo" />
                </div>
                
                <div className="footer-links">
                    {/* Additional footer links can go here */}
                </div>

                <div className="footer-social-media">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
            </div>

            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} AWEB LLC. All rights reserved.</p>
                <div>
                    <a href="#" onClick={handlePrivacyModalToggle}>Privacy Policy</a>
                    <span> | </span>
                    <a href="#" onClick={handleTermsModalToggle}>Terms of Service</a>
                </div>
            </div>

            {showPrivacyModal && (
                <Modal title="Privacy Policy" onClose={handlePrivacyModalToggle}>
                    <div>{privacyPolicy}</div>
                </Modal>
            )}
            {showTermsModal && (
                <Modal title="Terms of Service" onClose={handleTermsModalToggle}>
                    <div>{termsOfService}</div>
                </Modal>
            )}
        </footer>
    );
}

const privacyPolicy = `
Privacy Policy

Effective Date: 1-1-2025

At AWEB LLC, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or use our services.

Information We Collect:
- Personal Identification Information: Name, email address, phone number, etc.
- Usage Data: Information about how you use our website and services.

How We Use Your Information:
- To provide and maintain our service
- To communicate with you
- To improve our website and services
- To comply with legal obligations

Data Security:
We implement reasonable security measures to protect your personal information.

Changes to This Privacy Policy:
We may update this Privacy Policy from time to time. We will notify you of any changes.

Contact Us:
If you have any questions about this Privacy Policy, please contact us at info@a-webco.net.
`;

const termsOfService = `
Terms of Service

Effective Date: 1-1-2025

By using AWEB LLC's services, you agree to comply with and be bound by the following terms and conditions.

Acceptance of Terms:
Your use of our services constitutes acceptance of these Terms of Service.

Modification of Terms:
We may modify these terms at any time. Your continued use of our services will signify your acceptance of the modified terms.

User Responsibilities:
You agree to use our services legally and ethically.

Limitation of Liability:
AWEB LLC shall not be held liable for any damages arising from your use of the services.

Termination:
We reserve the right to terminate your access to our services at our discretion.

Contact Us:
If you have any questions about these Terms, please contact us at info@a-webco.net.
`;

// Basic modal component
const Modal = ({ title, children, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{title}</h2>
                <button className="close-modal" onClick={onClose}>X</button>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    );
};

export default LandingFooter;