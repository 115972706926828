import React, { useContext, useState, useEffect} from 'react';
import { AppContext } from '../../AppContext';
import AuthorizedRepresentative from './AuthorizedRepresentative';
import TrustRegistration from './TrustRegistration';
import ConfirmTrust from './ConfirmTrust';
import UseCase from './useCase';
import Step1Image from '../../assets/images/astro-text-image-1.jpg';
import Step2Image from '../../assets/images/astro-text-image-2.jpg';
import Step3Image from '../../assets/images/astro-text-image-3.jpg';
import '../css/onboarding.css';

// document.addEventListener('DOMContentLoaded', function(event) {
// });

const OnboardingComponent = () => {
    const {newView, setNewView, setLoading} = useContext(AppContext);
    const [currentStep, setCurrentStep] = useState(1);
    const [trustId, setTrustId] = useState(null);

    
    const handleTrustConfirmationNext = () => {
        setCurrentStep(3);
    }

    const handleTrustRegistrationNext = () => {
        setCurrentStep(2);
    };

    const handleTrustRegistrationPrevious = () => {
        setCurrentStep(1);
    };

    return (
        <>
            <div className="content_container">
                <div className="onboarding-content">
                    <div className="onboardContainer">
                        <div className="onboarding-image">
                            <img src={currentStep === 1 ? Step1Image : currentStep === 2 ? Step2Image : Step3Image} />
                        </div>
                        {(currentStep === 1 || currentStep === 2 || currentStep === 3) && (
                            <TrustRegistration
                                setTrustId={setTrustId}
                                setCurrentStep={setCurrentStep}
                                currentStep={currentStep}
                            />
                        )}
                            {currentStep === 4 && (
                            <AuthorizedRepresentative
                                trustId={trustId}
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                            />
                        )}

                        {currentStep === 5 && <ConfirmTrust trustId={trustId} setNewView={setNewView} />}
                        {currentStep === 6 && <UseCase trustId={trustId} setNewView={setNewView} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default OnboardingComponent;