import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AssistantPhone from './AssistantPhone'; // Import the AssistantPhone component

import '../css/myphonenumbers.css';

const MyPhoneNumbers = () => {
    const { apiUrl, hmac, setLoading } = useContext(AppContext);
    const [myPhoneNumbers, setMyPhoneNumbers] = useState([]);

    useEffect(() => {
        getUserPhoneNumbers();
    }, []);

    const getUserPhoneNumbers = async () => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('userHmacId', hmac);
            formData.append('action', 'listMyPhoneNumbers');

            const response = await axios.post(apiUrl, formData);
            if (response.data.status === 'success') {
                const rd = response.data.data;
                setMyPhoneNumbers(rd);
            } else {
                // console.log('No phone numbers found.');
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="my_phone_container">
                <h3>Your Current AI Assistants</h3>
                {myPhoneNumbers && myPhoneNumbers.length > 0 ? (
                    <div id="my_phone_numbers">
                        {myPhoneNumbers?.map((phoneNumber) => (
                            <AssistantPhone
                                key={phoneNumber.user_phone_id}
                                phoneNumber={phoneNumber}
                            />
                        ))}
                    </div>
                ) : (
                    <p>You don't currently have any AstroVoice assistants yet.</p>
                )}
            </div>
        </>
    );
};

export default MyPhoneNumbers;
