import React from 'react';
import { useNavigate} from "react-router-dom";
import './Hero.css';
import { HiArrowNarrowDown } from "react-icons/hi";


const Hero = () => {
    const navControl = useNavigate();

    const smoothScrollTo = (event, targetId) => {
        event.preventDefault(); // Prevent the default anchor behavior
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id="hero" className="hero">
            <div className="hero-content">
                <div className='hero-blurb'>
                    <h1>Elevate Your Customer Experience with Intelligent Communication Solutions</h1>
                    <p>Streamlining Customer Communication Through Automated Voice and Text Solutions</p>
                </div>                
                <div className='cta-buttons'>
                    <button className="cta-button" onClick={() => {navControl('/account');}}>Get Started</button>
                    <a className="learn-button" onClick={(e) => {smoothScrollTo(e,'astrovoice-key-features')}}>Learn More <HiArrowNarrowDown /></a>
                </div>
            </div>
        </div>
    );
}

export default Hero;