import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, useNavigate, Routes, Route } from "react-router-dom";
import { AppProvider } from './AppContext';
import './App.css';
import Main from './components/jsx/Main';
import Auth from './components/jsx/Auth';
import LandingPage from './components/LandingPage/LandingPage'; 

const App = () => {    
    return (
            <BrowserRouter>
                <AppProvider>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/auth" element={<Auth />} />
                            <Route path="/account" element={<Main />} />
                        </Routes>   
                    </div>
                </AppProvider>
            </BrowserRouter>  
    );
}

export default App;