import React from 'react';
import '../css/incoming.css';

const CommunicationTypeSelector = ({ showCommunicationTypes, selectedCommunicationType, onSelectCommunicationType }) => {
    return (
        showCommunicationTypes && (
            <div className="communication-types">
                <div>
                    <button onClick={() => onSelectCommunicationType('sms')}>
                        View Text Messages
                    </button>
                    <button onClick={() => onSelectCommunicationType('calls')}>
                        View Phone Call Transcriptions
                    </button>
                </div>
            </div>
        )
    );
};

export default CommunicationTypeSelector;
