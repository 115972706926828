import React, { useEffect } from 'react';
import { useNavigate} from "react-router-dom";
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import KeyFeatures from './KeyFeatures';
import FAQ from './FAQ';
import CallToAction from './CallToAction';
import Pricing from './Pricing';
import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';
import './landingpage.css';

const LandingPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const checkHmac = () => {
            const hmac = sessionStorage.getItem('hmac_key');
            if (hmac) {
                navigate('/account');
            }
        }

        checkHmac();
    });



    return (
        <>
            <LandingHeader />
            <Hero />
            <HowItWorks />
            <KeyFeatures />
            <Pricing />
            <FAQ />
            <CallToAction />
            <LandingFooter />
        </>
    );
}

export default LandingPage;